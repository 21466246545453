<template>
  <div class="text-center input-div">
    <confirm-modal
      v-if="showModal"
      :title="modalTitle"
      :message="modalMessage"
      @confirmed="onConfirmed"
      @declined="onDeclined"
    />
    <h2 class="mb-3" style="color: #1f7aaf">
      <span class="material-icons md-48"> list </span>
      {{ $englishPreferred ? "Observations" : "Observationer" }}
    </h2>
    <p
      v-if="
        collectionForm != null &&
        collectionForm.observations != null &&
        collectionForm.observations.length < 1
      "
    >
      {{
        $englishPreferred
          ? "Fill specie and behaviour to add a observation"
          : "Angiv art og adfærd for at tilføje en observation"
      }}
    </p>
    <div>
      <div v-if="allMandatoryFieldsFilled">
        <span v-for="tab in tabs" :key="tab">
          <button
            v-bind:key="tab.enTitle"
            v-bind:class="[
              'mb-3',
              'btn',
              'btn-outline-secondary',
              { active: currentTab === tab.enTitle },
            ]"
            v-on:click="currentTab = tab.enTitle"
          >
            {{ tab.daTitle }}
          </button>
          <div class="divider"></div>
        </span>
      </div>
      <component
        :ref="currentTabComponent"
        :collection="collectionForm"
        :currentObservation="currentObservation"
        v-bind:is="currentTabComponent"
        class="tab"
      ></component>

      <div class="mb-3">
        <div
          class="row"
          v-if="allMandatoryFieldsFilled && currentObservationIndex != null"
        >
          <div class="col-10">
            <button
              @click="updateSelectedObservation"
              class="btn btn-default form-control"
            >
              Opdater observation
              <i class="material-icons md-20 button-material-icon"> check </i>
            </button>
          </div>
          <div class="col">
            <i
              @click="cancelUpdate"
              class="material-icons md-28 clickable-icon button-material-icon"
            >
              close
            </i>
          </div>
        </div>

        <button
          v-if="allMandatoryFieldsFilled && currentObservationIndex == null"
          @click="addCurrentObservationToCollection"
          class="btn-lg btn-primary form-control"
        >
          {{ $englishPreferred ? "Save observation" : "Gem observation" }}
        </button>
      </div>
    </div>
    <hr />

    <div v-if="collectionForm != null && collectionForm.observations != null">
      <observation-list-item
        @remove-from-array="removeFromArray"
        @edit-selected-observation="editSelectedObservation"
        :key="index"
        :observation="observation"
        v-for="(observation, index) in this.collectionForm.observations"
      >
      </observation-list-item>
    </div>
  </div>
</template>

<script>
import MandatoryTab from "./MandatoryTab.vue";
import OptionalTab from "./OptionalTab.vue";
import ObservationListItem from "./ObservationListItem.vue";
import observationRepository from "../../idb/repositories/observationRepository";
import recentBehavioursRepository from "../../idb/repositories/recentBehavioursRepository";
import recentSpeciesRepository from "../../idb/repositories/recentSpeciesRepository";
import axios from "axios";
import ConfirmModal from "../ConfirmModal.vue";

export default {
  props: ["currentObservation", "collectionForm"],
  components: {
    MandatoryTab,
    OptionalTab,
    ObservationListItem,
    ConfirmModal,
  },
  data() {
    return {
      startTime: Date.now(),
      // If an already added observation is being edited, temporarily store the observations index in array
      currentObservationIndex: null,
      currentTab: "Mandatory",
      tabs: [
        { enTitle: "Mandatory", daTitle: "Påkrævet" },
        { enTitle: "Optional", daTitle: "Valgfrit" },
      ],
      showModal: false,
      modalTitle: "",
      modalMessage: "",
      existingObservation: null,
    };
  },
  methods: {
    showConfirmModal() {
      this.modalTitle = "Duplikat observation";
      this.modalMessage =
        "Matchende observation fundet. Du har allerede tilføjet en anden observation med samme art, køn, alder, dragt og adfærd. Ønsker du at tillægge antallet af denne observation til den eksisterende?";
      this.showModal = true;
    },
    onConfirmed() {
      // Handle the OK button click here
      // This function is called when the user clicks "OK" in the modal
      this.showModal = false;

      console.log("accepted");
      // Add the count of current observation to count of the existing one that matches
      this.existingObservation.count += this.currentObservation.count;
      // If observation has a collectionId save the increment directly
      if (this.existingObservation.collectionId != null) {
        // Copy the observation as a json object
        let unwrappedObservation = {
          ...JSON.parse(JSON.stringify(this.existingObservation)),
        };
        if (unwrappedObservation.apiId) {
          unwrappedObservation.unsynched = true;
          // Update in API
          axios
            .put(
              "/spontan/observations/" + unwrappedObservation.apiId,
              unwrappedObservation
            )
            .then(() => {
              console.log(
                "Successfully updated in DOFbasen, now updating in locally"
              );
              // Success, now update locally with unsynched marked as true
              unwrappedObservation.unsynched = false;
              observationRepository.insertObservation(unwrappedObservation);
            })
            .catch(() => {
              console.log("Server did not respond, updating locally");
              observationRepository.insertObservation(unwrappedObservation);
            });
        } else {
          // Insert into indexedDB
          observationRepository.insertObservation(unwrappedObservation);
        }
      }
    },
    onDeclined() {
      this.showModal = false;
    },
    focusInput() {
      if (window.innerWidth > 768) {
        // retrieve the element by its id
        const element = document.getElementById("specie-picker");

        // focus the element
        element.focus();
      }
    },
    editSelectedObservation(index) {
      // Set "isBeingEdited" field to false if currentObservationIndex is defined
      if (this.currentObservationIndex != null) {
        this.collectionForm.observations[
          this.currentObservationIndex
        ].isBeingEdited = false;
      }
      this.currentObservationIndex = index;
      for (var k in this.collectionForm.observations[index]) {
        this.currentObservation[k] = this.collectionForm.observations[index][k];
      }
      this.currentObservation.chosenBehaviours = {
        primary:
          this.collectionForm.observations[index].chosenBehaviours.primary,
        secondary:
          this.collectionForm.observations[index].chosenBehaviours.secondary,
        direction:
          this.collectionForm.observations[index].chosenBehaviours.direction,
      };
    },
    updateSelectedObservation() {
      if (this.canCurrentObservationBeAddedToAnyExistingObservation()) {
        console.log("could be added to existing observation");
        return;
      }
      // Set values for observation displayed in list
      for (var k in this.currentObservation) {
        this.collectionForm.observations[this.currentObservationIndex][k] =
          this.currentObservation[k];
      }
      // Insert updates in indexeddb if this already belongs to a collection
      this.currentObservation.isBeingEdited = false;
      this.currentObservation.unsynched = true;
      if (this.collectionForm.id != null) {
        let unwrappedObservation = {
          ...JSON.parse(JSON.stringify(this.currentObservation)),
        };
        if (unwrappedObservation.apiId) {
          // Update observation in API, and when done, update locally as well
          axios
            .put(
              "/spontan/observations/" + unwrappedObservation.apiId,
              unwrappedObservation
            )
            .then(() => {
              console.log(
                "Successfully updated in DOFbasen, now updating in local database"
              );
              // Success, now update locally with unsynched marked as true
              unwrappedObservation.unsynched = false;
              observationRepository
                .insertObservation(unwrappedObservation)
                .then(() => {
                  this.clearCurrentObservation();
                });
            })
            .catch(() => {
              console.log("Server did not respond, updating locally");
              observationRepository
                .insertObservation(unwrappedObservation)
                .then(() => {
                  this.clearCurrentObservation();
                });
            });
        } else {
          observationRepository
            .insertObservation(unwrappedObservation)
            .then(() => {
              this.clearCurrentObservation();
            });
        }
      } else {
        // Otherwise simply clear current observation
        this.clearCurrentObservation();
      }
      this.currentObservationIndex = null;
      this.currentTab = "Mandatory";
    },
    cancelUpdate() {
      // Set "isBeingEdited" field to false
      this.collectionForm.observations[
        this.currentObservationIndex
      ].isBeingEdited = false;
      this.clearCurrentObservation();
      this.currentObservationIndex = null;
      this.currentTab = "Mandatory";
    },
    removeFromArray(index) {
      this.collectionForm.observations.splice(index, 1);
      this.clearCurrentObservation();
      this.currentObservationIndex = null;
    },
    canCurrentObservationBeAddedToAnyExistingObservation() {
      // Check if observation fits with another observation in this count
      // It fits if specie, sex, age, plumage, and behaviour is the same
      let canBeAddedToExisting = false;
      this.collectionForm.observations.forEach((observation) => {
        if (canBeAddedToExisting == true || observation.isBeingEdited == true) {
          return;
        }
        // Specie
        if (this.currentObservation.specie !== observation.specie) {
          return;
        }
        // Sex
        else if (this.currentObservation.sex !== observation.sex) {
          return;
        }
        // Age
        else if (this.currentObservation.age !== observation.age) {
          return;
        }
        // Plumage
        else if (this.currentObservation.plumage !== observation.plumage) {
          return;
        }
        // Behaviour
        else if (
          this.currentObservation.chosenBehaviours.primary !==
            observation.chosenBehaviours.primary ||
          this.currentObservation.chosenBehaviours.secondary !==
            observation.chosenBehaviours.secondary ||
          this.currentObservation.chosenBehaviours.direction !==
            observation.chosenBehaviours.direction
        ) {
          return;
        }
        // Is equal to existing observation on all key values, so this can be added to the existing one
        this.existingObservation = observation;
        this.showConfirmModal();

        this.clearCurrentObservation();
        canBeAddedToExisting = true;
        return;
      });

      return canBeAddedToExisting;
    },
    addCurrentObservationToCollection() {
      if (this.canCurrentObservationBeAddedToAnyExistingObservation()) {
        console.log("could be added to existing observation");
        return;
      }
      this.currentObservation.startTime = this.collectionForm.startTime;
      this.currentObservation.endTime = this.collectionForm.endTime;
      this.currentObservation.unsynched = true;
      // Insert specie and behaviour into recent log in indexeddb
      let unwrappedChosenBehaviours = {
        ...JSON.parse(JSON.stringify(this.currentObservation.chosenBehaviours)),
      };
      recentSpeciesRepository.insertRecentSpecie(
        this.currentObservation.specie
      );
      recentBehavioursRepository.insertRecentBehaviour(
        unwrappedChosenBehaviours
      );

      if (this.collectionForm.id != null) {
        let unwrappedObservation = {
          ...JSON.parse(JSON.stringify(this.currentObservation)),
        };

        if (this.collectionForm.apiId != null) {
          unwrappedObservation.collectionApiId = this.collectionForm.apiId;
          axios
            .post("/spontan/observations/", unwrappedObservation)
            .then((response) => {
              let observationApiId = response.data;
              unwrappedObservation.apiId = observationApiId;
              unwrappedObservation.unsynched = false;

              observationRepository
                .insertObservation(unwrappedObservation)
                .then((observationId) => {
                  this.currentObservation.id = observationId;
                  this.currentObservation.unsynched = false;
                  this.currentObservation.apiId = observationApiId;
                  this.collectionForm.observations.unshift({
                    ...this.currentObservation,
                  });
                  this.clearCurrentObservation();
                  this.currentTab = "Mandatory";
                });
            })
            .catch((error) => {
              console.log(
                "error occurred while communicating with DOFbasen trying to synchronize observation"
              );
              observationRepository
                .insertObservation(unwrappedObservation)
                .then((observationId) => {
                  this.currentObservation.id = observationId;
                  this.collectionForm.observations.unshift({
                    ...this.currentObservation,
                  });
                  this.clearCurrentObservation();
                  this.currentTab = "Mandatory";
                });
            });
        }
      } else {
        this.collectionForm.observations.unshift({
          ...this.currentObservation,
        });
        this.clearCurrentObservation();
        this.currentTab = "Mandatory";
      }
      // update recent species and behaviours
      this.currentTab = "Mandatory";
      setTimeout(() => {
        this.$refs.MandatoryTab.refreshRecent();
      }, 300);
      this.focusInput();
    },
    clearCurrentObservation() {
      if (this.currentObservation.id != null) {
        delete this.currentObservation.id;
      }
      (this.currentObservation.apiId = null),
        (this.currentObservation.collectionId =
          this.collectionForm.id != null ? this.collectionForm.id : null),
        (this.currentObservation.count = 1);
      this.currentObservation.behaviour = null;
      this.currentObservation.specie = null;
      this.currentObservation.age = 1;
      this.currentObservation.sex = "-";
      this.currentObservation.plumage = 1;
      this.currentObservation.secret = false;
      this.currentObservation.notes = null;
      this.currentObservation.calling = false;
      this.currentObservation.heard = false;
      this.currentObservation.migratingInwards = false;
      this.currentObservation.migratingOutwards = false;
      this.currentObservation.oilDamaged = false;
      this.currentObservation.startTime = null;
      this.currentObservation.endTime = null;
      (this.currentObservation.coordinate = {
        longitude: null,
        latitude: null,
        radius: 50,
      }),
        (this.currentObservation.chosenBehaviours = {
          primary: null,
          secondary: null,
          direction: null,
        }),
        (this.currentObservation.isBeingEdited = false);
    },
  },
  computed: {
    currentTabComponent() {
      return this.currentTab + "Tab";
    },
    allMandatoryFieldsFilled() {
      if (
        this.currentObservation.specie != null &&
        this.currentObservation.chosenBehaviours.primary != null &&
        this.currentObservation.count != null
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.md-48 {
  position: relative;
  /* Adjust these values accordingly */
  top: 9px;
  font-size: 38px !important;
}
</style>
