import idb from "../idb.js";

export default {
  async insertRecentSpecie(recentSpecie) {
    //First get all current recent species, and delete any that shares same values as the new one
    let existingRecentSpecies = await this.getRecentSpecies();
    existingRecentSpecies.forEach((existing) => {
      // Check if their values are the same:
      if (existing.specieId == recentSpecie) {
        this.deleteRecentSpecieById(existing.id);
      }
    });

    let db = await idb.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["recentSpecies"], "readwrite");
      let store = trans.objectStore("recentSpecies");

      store.put({ specieId: recentSpecie });
    });
  },

  async deleteRecentSpecieById(id) {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      db.transaction("recentSpecies", "readwrite")
        .objectStore("recentSpecies")
        .delete(+id);
    });
  },

  async getRecentSpecies() {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["recentSpecies"], "readonly");
      trans.oncomplete = () => {
        resolve(recentSpecies.reverse().slice(0, 30));
      };

      let store = trans.objectStore("recentSpecies");
      let recentSpecies = [];

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          recentSpecies.push(cursor.value);
          cursor.continue();
        }
      };
    });
  },
};
