<template>
  <nav-header
    @navigate-backwards="navigateBackwards"
    :isInstructionsPage="isInstructionsPage"
    :isCountInProcess="isCountInProcess"
    :countIsCompleted="showCountCompletion"
    :title="navTitle"
  ></nav-header>
  <div>
    <MapCompletedCount
      v-show="showMapCompletedCount"
      @switch-to-date-and-time="switchToDateAndTime"
      :collectionForm="collectionForm"
    />
    <PickDateAndTime
      v-if="showPickDateAndTime"
      @switch-to-observations="switchToObservations"
      :collectionForm="collectionForm"
    />
    <AddCompletedObservationsToCount
      v-if="showObservations"
      @submit-completed-count="submitCompletedCount"
      :collectionForm="collectionForm"
      :currentObservation="currentObservation"
      :observations="collectionForm.observations"
    />
    <CountConfirmation
      v-if="showCountConfirmation"
      @confirm-completed-count="confirmCompletedCount"
      :collectionForm="collectionForm"
      @change-position-after-count="changePositionAfterCount"
    />

    <CountCompletion
      :countPoints="countPoints"
      :collectionForm="collectionForm"
      v-if="showCountCompletion"
    />
  </div>
</template>

<script>
import MapCompletedCount from "../components/MapCompletedCount.vue";
import PickDateAndTime from "../components/PickDateAndTime.vue";
import CountConfirmation from "../components/CountConfirmation.vue";
import CountCompletion from "../components/CountCompletion.vue";
import NavHeader from "@/components/navbars/NavHeader.vue";
import AddCompletedObservationsToCount from "../components/AddObservationsToCompletedCount.vue";
import observationRepository from "../idb/repositories/observationRepository";
import recentBehavioursRepository from "../idb/repositories/recentBehavioursRepository";
import recentSpeciesRepository from "../idb/repositories/recentSpeciesRepository";
import countPointsRepository from "../idb/repositories/countPointsRepository";
import collectionRepository from "../idb/repositories/collectionRepository";
import atlasSquarePointRepository from "../idb/repositories/atlasSquarePointRepository";
import axios from "axios";

export default {
  name: "AddCompletedCount",
  components: {
    MapCompletedCount,
    PickDateAndTime,
    CountConfirmation,
    CountCompletion,
    NavHeader,
    AddCompletedObservationsToCount,
  },
  methods: {
    navigateBackwards() {
      if (this.showPickDateAndTime) {
        this.showPickDateAndTime = false;
        this.isInstructionsPage = true;
        this.showMapCompletedCount = true;
      } else if (this.showObservations) {
        this.showObservations = false;
        this.showPickDateAndTime = true;
      } else if (this.showCountConfirmation) {
        this.showCountConfirmation = false;
        this.showObservations = true;
      }
    },
    switchToDateAndTime() {
      this.showMapCompletedCount = false;
      this.showPickDateAndTime = true;
      this.isInstructionsPage = false;
    },
    switchToObservations() {
      this.showPickDateAndTime = false;
      const d = new Date();
      // Set end time
      const dEnd = this.addMinutes(d, 5);
      this.showObservations = true;
    },
    addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },
    addMinutes(date, minutes) {
      return new Date(date.getTime() + minutes * 60000);
    },
    submitCompletedCount() {
      this.showObservations = false;
      this.showCountConfirmation = true;
    },
    changePositionAfterCount() {
      // First navigate back to observations, then to date, then to map
      this.navigateBackwards();
      this.navigateBackwards();
      this.navigateBackwards();
    },
    async confirmCompletedCount() {
      this.isCountInProcess = false;
      this.showCountConfirmation = false;
      this.showCountCompletion = true;
      // Copy observations in collectionform, preparing them to be inserted in indexeddb separately
      let observations = JSON.parse(
        JSON.stringify(this.collectionForm.observations)
      );
      // Now remove observations from collectionform
      delete this.collectionForm.observations;
      // Insert collectionForm in IndexedDB and retrieve ID, then insert observations, then insert recent species and behaviours
      let unwrappedCollection = {
        ...JSON.parse(JSON.stringify(this.collectionForm)),
      };
      await collectionRepository
        .insertCollection(unwrappedCollection)
        .then(async (localCollectionId) => {
          unwrappedCollection.id = localCollectionId;
          await this.addObservationsToDatabase(observations, localCollectionId);
          await this.addCountPointsToDatabase(localCollectionId);
          axios
            .post("/spontan/collections/", unwrappedCollection)
            .then((response) => {
              let collectionApiId = response.data;
              console.log(
                "adding observations to DB, collectionApiID: " + collectionApiId
              );
              unwrappedCollection.apiId = collectionApiId;

              collectionRepository.insertCollection(unwrappedCollection);
              observations.forEach((observation) => {
                observation.collectionApiId = collectionApiId;
                // For each observation send it to API, and then set the apiId for it, and update it in local database
                axios
                  .post("/spontan/observations/", observation)
                  .then((response) => {
                    let observationApiId = response.data;
                    observation.apiId = observationApiId;
                    observation.collectionId = localCollectionId;
                    observation.unsynched = false;
                    observationRepository.insertObservation(observation);
                  });
              });
            });
        });

      // Recent choices
      observations.forEach((observation) => {
        //recentBehaviour
        recentBehavioursRepository.insertRecentBehaviour(
          observation.chosenBehaviours
        );
        //recentSpecie
        recentSpeciesRepository.insertRecentSpecie(observation.specie);
      });
    },
    addObservationsToDatabase(observations, collectionId) {
      observations.forEach((observation) => {
        observation.collectionId = collectionId;
        observationRepository
          .insertObservation(observation)
          .then((localObservationId) => {
            observation.id = localObservationId;
          });
      });
    },
    isDateInThisWeek(date) {
      const todayObj = new Date();
      const todayDate = todayObj.getDate();
      const todayDay = todayObj.getDay();

      // get first date of week
      const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));

      // get last date of week
      const lastDayOfWeek = new Date(firstDayOfWeek);
      lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

      // if date is equal or within the first and last dates of the week
      return date >= firstDayOfWeek && date <= lastDayOfWeek;
    },
    async addCountPointsToDatabase(collectionId) {
      /** COUNT POINTS
       * 1. Figure out how many points this count is awarded
       * 2. Insert countPoints in IndexedDB
       */

      // Check if date of the count is within the last 14 days, if not, don't award/save any points for this count
      const currentDate14DaysAgo = new Date(Date.now() - 12096e5);
      const selectedDate = new Date(this.collectionForm.date);
      if (selectedDate < currentDate14DaysAgo) {
        console.log(
          "This count is more than two weeks old, and so it won't provide any points"
        );
        return;
      }

      let datetime = new Date(
        "01-01-2017 " + this.collectionForm.startTime + ":00"
      );
      let hours = datetime.getHours();
      let minutes = datetime.getMinutes();
      let pointDateTime = selectedDate;
      pointDateTime.setHours(hours);
      pointDateTime.setMinutes(minutes);

      console.log("Is within allowed time limit to gain points ");
      let countPointsOnSameAtlasSquare =
        await countPointsRepository.getCountPointsByAtlasSquareNumber(
          this.collectionForm.atlasSquareNumber
        );
      // try to fetch points from atlasSquarePoints if any exists for this square, otherwise give 100 by default
      let atlasSquarePoint =
        await atlasSquarePointRepository.getAtlasSquarePointByAtlasSquareNumber(
          this.collectionForm.atlasSquareNumber
        );
      let points = 100;
      if (atlasSquarePoint != null && atlasSquarePoint.length !== 0) {
        points = atlasSquarePoint[0].points;
        console.log(
          "adjusted points because atlas square point was found. Points: " +
            points
        );
      }

      const hour = 1000 * 60 * 60;
      let givePoints = false;
      let recentCount = null;
      if (
        countPointsOnSameAtlasSquare != null &&
        countPointsOnSameAtlasSquare.length !== 0
      ) {
        for (let i = 0; i < countPointsOnSameAtlasSquare.length; i++) {
          const pointDate = new Date(pointDateTime);
          const countPointDate = new Date(
            countPointsOnSameAtlasSquare[i].datetime
          );

          // Set the time of day to midnight (00:00:00)
          pointDate.setHours(0, 0, 0, 0);
          countPointDate.setHours(0, 0, 0, 0);

          if (pointDate.getTime() === countPointDate.getTime()) {
            console.log("same date, 10 points");
            points = 10;
          }

          if (
            pointDateTime.getTime() <
            countPointsOnSameAtlasSquare[i].datetime.getTime() + hour
          ) {
            if (
              recentCount != null &&
              recentCount.datetime.getTime() <
                countPointsOnSameAtlasSquare[i].datetime.getTime()
            ) {
              recentCount = countPointsOnSameAtlasSquare[i];
            } else if (recentCount == null) {
              recentCount = countPointsOnSameAtlasSquare[i];
            }
          }
        }
      }
      if (recentCount != null) {
        // Check recent count gave more than 0 points, and another count within 1 hour of the recent count found,
        // but is also more than one hour ago from the current count - if no such count is found, set points to 0
        for (let i = 0; i < countPointsOnSameAtlasSquare.length; i++) {
          if (
            // current count more than an hour ago from count on same point, and recentCount
            pointDateTime.getTime() >
              countPointsOnSameAtlasSquare[i].datetime.getTime() + hour &&
            recentCount.datetime.getTime() <
              countPointsOnSameAtlasSquare[i].datetime.getTime() + hour &&
            recentCount.points == 0
          ) {
            givePoints = true;
          }
        }
        if (givePoints === false) {
          points = 0;
        }
      }

      let countPoints = {
        kvadratnr: this.collectionForm.atlasSquareNumber,
        collectionId: collectionId,
        collectionIdApi: null,
        datetime: pointDateTime,
        points: points,
      };

      // Set collectionIdApi
      if (this.collectionForm.apiId) {
        countPoints.collectionIdApi = this.collectionForm.apiId;
      }

      // Set this.countPoints, so we can show points in next view
      this.countPoints = countPoints;

      // 2. insert countPoints into indexedDB
      countPointsRepository.insertCountPoints(countPoints);
    },
  },
  data() {
    return {
      navTitle: this.$englishPreferred ? "Create count" : "Indtast tælling",
      isCountInProcess: true,
      countPoints: null,
      currentObservation: {
        apiId: null,
        collectionId: null,
        collectionApiId: null,
        count: 1,
        specie: null,
        age: 1,
        sex: "-",
        plumage: 1,
        secret: false,
        notes: null,
        calling: false,
        heard: false,
        migratingInwards: false,
        migratingOutwards: false,
        oilDamaged: false,
        startTime: null,
        endTime: null,
        coordinate: {
          latitude: null,
          longitude: null,
          radius: 50,
        },
        chosenBehaviours: {
          primary: null,
          secondary: null,
          direction: null,
        },
      },
      collectionForm: {
        apiId: null,
        date: null,
        startTime: null,
        endTime: null,
        site: null,
        coObserver: null,
        windDirection: null,
        windForce: null,
        cloudCover: null,
        temperature: null,
        downpour: null,
        downpourType: null,
        visibility: null,
        notes: null,
        observations: [],
        latitude: null,
        longitude: null,
        atlasSquareNumber: null,
      },
      showMapCompletedCount: true,
      showPickDateAndTime: false,
      showObservations: false,
      showCountConfirmation: false,
      showCountCompletion: false,
      isInstructionsPage: true,
    };
  },
};
</script>
