<template>
  <div>
    <div class="container">
      <div class="row mt-5">
        <div class="col">
          <h5 class="mb-4">
            {{
              $englishPreferred
                ? "Thank you for your contribution!"
                : "Tak for din indtastning!"
            }}
          </h5>
          <p v-if="countPoints">
            {{ $englishPreferred ? "You are awarded" : "Du er blevet tildelt" }}
            <span v-if="showPoints">{{ countPoints.points }}</span>
            {{
              $englishPreferred
                ? "points for this count"
                : "point for denne tælling"
            }}
          </p>
          <p>
            {{
              $englishPreferred
                ? 'See and edit your observations in "My counts"'
                : 'Se og ret dine observationer i "Mine tællinger"'
            }}
          </p>
          <p v-if="!countIsToday && countIsWithinTwoWeeks">
            {{
              $englishPreferred
                ? 'Counts done before today are given same amount of points as was applicable for the given date. See points in "My counts"'
                : 'Tællinger udført før i dag tildeles samme antal point som var gældende for den angivne dato. Se point i "Mine tællinger"'
            }}
          </p>
          <p v-if="!countIsWithinTwoWeeks">
            {{
              $englishPreferred
                ? "Counts done more than 14 days ago does not receive points, but are still counted in your total."
                : "Tællinger udført mere end 14 dage siden, tildeles ikke point, men tæller stadig med i din total."
            }}
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="bottom-nav">
    <router-link
      :to="{ name: 'CompletedCount' }"
      tag="button"
      class="btn btn-default bottom-action"
    >
      OK
    </router-link>
  </div>
</template>

<script>
export default {
  props: ["countPoints", "collectionForm"],
  data() {
    return {
      currentDate: new Date(),
      selectedDate: new Date(this.collectionForm.date),
      countIsToday: true,
      countIsWithinTwoWeeks: true,
    };
  },
  computed: {
    showPoints() {
      if (this.currentDate != null && this.selectedDate != null) {
        return (
          this.currentDate.toDateString() === this.selectedDate.toDateString()
        );
      } else {
        return true;
      }
    },
  },
  methods: {
    isToday(dateString) {
      const today = new Date();
      const date = new Date(dateString);

      // 👇️ Today's date
      console.log(today);

      if (today.toDateString() === date.toDateString()) {
        return true;
      }

      return false;
    },
    isMoreThanTwoWeeksAgo(dateString) {
      const currentDate14DaysAgo = new Date(Date.now() - 12096e5);
      const selectedDate = new Date(dateString);

      if (selectedDate < currentDate14DaysAgo) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted: function () {
    if (!this.isToday(this.collectionForm.date)) {
      this.countIsToday = false;
    }
    if (this.isMoreThanTwoWeeksAgo(this.collectionForm.date)) {
      this.countIsWithinTwoWeeks = false;
    }
  },
};
</script>
