<template>
  <div>
    <div class="container">
      <div class="row mt-5">
        <div class="col">
          <p>
            {{ $englishPreferred ? "Spontaneous count" : "Spontantælling" }}: {{ collectionForm.date }} <br />
            {{ $englishPreferred ? "Start time" : "Starttid" }}: {{ collectionForm.startTime }} <br />
            {{ $englishPreferred ? "Duration" : "Varighed" }}: 5:00
          </p>
          <div class="row mb-5">
            <div class="col">
              <p>
                {{ $englishPreferred ? "Position" : "Placering" }}:
                <button
                  class="btn-xs btn-outline-secondary"
                  v-on:click="$emit('change-position-after-count')"
                >
                {{ $englishPreferred ? "Change" : "Skift" }}
                </button>
              </p>
              <div class="mb-4">
                <div id="tinymapid"></div>
              </div>

              <div>
                <button
                  class="btn btn-outline-primary"
                  type="button"
                  @click="toggleOptionals"
                >
                  {{
                    showOptionals
                      ? $englishPreferred ? "Hide optional data" : "Skul valgfrit data om turen"
                      : $englishPreferred ? "Add optional data" : "Tilføj valgfri data om turen"
                  }}
                </button>
              </div>
              <div v-if="showOptionals">
                <div class="card card-body">
                  <h4 class="text-center mb-3">{{ $englishPreferred ? "Optional" : "Valgfrit" }}</h4>
                  <div class="mb-3 input-group">
                    <span class="input-group-text">{{ $englishPreferred ? "Tour notes" : "Turnoter" }}</span>
                    <textarea
                      v-model="collectionForm.notes"
                      class="form-control"
                    ></textarea>
                  </div>

                  <div class="mb-3 input-group">
                    <span class="input-group-text">{{ $englishPreferred ? "Co-observers" : "Medobservatører" }}</span>
                    <input
                      type="text"
                      placeholder="Medobservatører"
                      v-model="collectionForm.coObserver"
                      class="form-control"
                    />
                  </div>
                  <hr />
                  <h5 class="text-center mb-3">{{ $englishPreferred ? "Weather" : "Vejret" }}</h5>

                  <div class="mb-3 input-group">
                    <span class="input-group-text" id="wind-direction-text"
                      >Vindretning</span
                    >
                    <Multiselect
                      class="form-select"
                      v-model="collectionForm.windDirection"
                      :options="windDirections"
                      placeholder="Vælg vindretning"
                      searchable="true"
                      ref="multiselect"
                    />
                  </div>

                  <div class="mb-3 input-group">
                    <span class="input-group-text">{{ $englishPreferred ? "Windforce" : "Vindstyrke" }} (m/s)</span>
                    <input
                      type="number"
                      placeholder="Vindstyrke"
                      v-model="collectionForm.windForce"
                      class="form-control"
                    />
                  </div>

                  <div class="mb-3 input-group">
                    <span class="input-group-text" id="cloud-cover-text"
                      >{{ $englishPreferred ? "Cloud cover (1/8)" : "Skydække (ottendedele)" }}</span
                    >
                    <Multiselect
                      class="form-select"
                      v-model="collectionForm.cloudCover"
                      :options="cloudCovers"
                      placeholder="Skydække"
                      searchable="true"
                      ref="multiselect"
                    />
                  </div>

                  <div class="mb-3 input-group">
                    <span class="input-group-text">{{ $englishPreferred ? "Temperature" : "Temperatur" }}</span>
                    <input
                      type="number"
                      placeholder="Temperatur"
                      v-model="collectionForm.temperature"
                      class="form-control"
                    />
                  </div>

                  <div class="mb-3 input-group">
                    <span class="input-group-text" id="downpour-type-text"
                      >{{ $englishPreferred ? "Precipitation type" : "Nedbørstype" }}</span
                    >
                    <Multiselect
                      class="form-select"
                      v-model="collectionForm.downpourType"
                      :options="downpourTypes"
                      placeholder="Vælg typen af nedbør"
                      searchable="true"
                      ref="multiselect"
                    />
                  </div>

                  <div class="mb-3 input-group">
                    <span class="input-group-text">{{ $englishPreferred ? "Precipitation amount" : "Nedbørsmægnde" }} (mm)</span>
                    <input
                      type="number"
                      placeholder="Nedbørsmængde"
                      v-model="collectionForm.downpour"
                      class="form-control"
                    />
                  </div>

                  <div class="mb-3 input-group">
                    <span class="input-group-text">{{ $englishPreferred ? "Visibile (meters)" : "Sigtbarhed (meter)" }}</span>
                    <input
                      type="number"
                      placeholder="Sigtbarhed"
                      v-model="collectionForm.visibility"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {{ $englishPreferred ? "Shown in DOFbasen under location" : "Vises i DOFbasen under lokaliteten" }}: <br />
          <b>
          "<span>{{
            collectionForm.site.na
          }}</span>
          "
          </b>
          <button
            @click="toggleShowChangeLocationOptions()"
            class="btn-xs btn-outline-secondary"
            :class="showChangeLocationOptions ? '' : 'mb-5'"
          >
          {{ $englishPreferred ? "Change" : "Skift" }}
          </button>
        </div>
      </div>
      <div class="row mt-2 mb-5" v-if="showChangeLocationOptions">
        <div class="col-8 text-center">
          <button
            @click="openNearestSitesModal"
            class="mb-3 btn btn-outline-primary form-control"
          >
          {{ $englishPreferred ? "Nearby locations" : "Nærmeste lokaliteter" }}
          </button>
        </div>
        <div class="col-4 text-center">
          <button
            type="button"
            @click="openSiteMapModal()"
            class="btn mb-3 btn-primary form-control"
          >
            <i class="material-icons md-20 button-material-icon"> map </i>
            {{ $englishPreferred ? "Map" : "Kort" }}
          </button>
        </div>
      </div>
    </div>
    <div class="bottom-nav">
      <button
        type="button"
        class="btn btn-default bottom-action"
        v-on:click="$emit('confirm-completed-count')"
      >
        <i class="material-icons button-material-icon"> done </i>
        {{ $englishPreferred ? "End" : "Afslut indtastning" }}
      </button>
    </div>

    <!-- Nearest Sites Modal -->
    <div
      class="modal fade"
      id="nearestSitesModal"
      tabindex="-1"
      aria-labelledby="nearestSitesModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="nearestSitesModalLabel">
              {{ $englishPreferred ? "Nearby locations" : "Nærmeste lokaliteter" }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div v-if="nearestSites.length > 0">
              <div
                class="mb-2"
                v-for="(nearestSite, index) in nearestSites"
                :key="index"
                @click="selectNearestSite(nearestSite)"
              >
                {{ nearestSite.na }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Site Map Modal -->
    <div
      class="modal fade"
      id="siteMapModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="siteMapModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-title" id="siteMapModalLabel">
              <h5>{{ $englishPreferred ? "Choose location" : "Vælg lokalitet" }}</h5>
              <span v-if="displaySiteChoice && siteToChoose != null">
                <br />
                {{ siteToChoose.na }}
                <button
                  @click="selectThisSite"
                  class="btn form-control btn-default"
                >
                {{ $englishPreferred ? "Choose" : "Vælg" }}
                </button>
              </span>
            </div>
            <button
              type="button"
              @click="dismissSiteMapModal()"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <edit-collection-map
              @show-site-map-choice="showSiteMapChoice"
              :isShowingSiteMapModal="isShowingSiteMapModal"
              :collectionForm="collectionForm"
              :sites="sites"
            ></edit-collection-map>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import "leaflet/dist/leaflet.css";
import leaflet from "leaflet";
import siteRepository from "../idb/repositories/siteRepository";
import EditCollectionMap from "../components/collection/EditCollectionMap.vue";
import { Modal } from "bootstrap";
delete leaflet.Icon.Default.prototype._getIconUrl;
leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
import { toRaw } from "vue";

export default {
  name: "countConfirmation",
  props: ["collectionForm"],
  components: {
    Multiselect,
    EditCollectionMap,
  },
  data() {
    return {
      mymap: null,
      marker: null,
      markerIsSet: false,
      showOptionals: false,
      showChangeLocationOptions: false,
      nearestSites: [],
      nearestSitesModal: null,
      siteMapModal: null,
      isShowingSiteMapModal: false,
      displaySiteChoice: false,
      siteToChoose: null,
      sites: [],
      windDirections: [
        "Nord (N)",
        "Nord-Nordøst (NNØ)",
        "Nordøst (NØ)",
        "Nordvest (NV)",
        "Nord-Nordvest (NNV)",
        "Øst-Nordøst (ØNØ)",
        "Øst (Ø)",
        "Øst-Sydøst (ØSØ)",
        "Sydøst (SØ)",
        "Syd-Sydøst (SSØ)",
        "Syd (S)",
        "Syd-Sydvest (SSV)",
        "Sydvest (SV)",
        "Vest-Sydvest (VSV)",
        "Vest (V)",
      ],
      cloudCovers: [...Array(9).keys()],
      downpourTypes: ["Regn", "Hagl", "Slud", "Sne"],
    };
  },
  methods: {
    selectThisSite() {
      this.collectionForm.site = this.siteToChoose;
      this.closeSiteMapModal();
    },
    dismissSiteMapModal() {
      this.isShowingSiteMapModal = false;
      this.displaySiteChoice = false;
      this.siteTotChoose = null;
    },
    showSiteMapChoice(site) {
      this.displaySiteChoice = true;
      this.siteToChoose = site;
    },
    closeSiteMapModal() {
      this.siteMapModal.hide();
      this.isShowingSiteMapModal = false;
    },
    openSiteMapModal() {
      this.siteMapModal = new Modal(
        document.getElementById("siteMapModal"),
        {}
      );
      this.siteMapModal.show();

      this.isShowingSiteMapModal = true;
    },
    toggleShowChangeLocationOptions() {
      this.showChangeLocationOptions = !this.showChangeLocationOptions;
    },
    toggleOptionals() {
      this.showOptionals = !this.showOptionals;
    },
    createMap() {
      this.mymap = leaflet
        .map("tinymapid", {
          center: [0, 0],
          dragging: false,
          scrollWheelZoom: "center",
        })

        .setView(
          [this.collectionForm.latitude, this.collectionForm.longitude],
          18
        );

      leaflet.tileLayer
        .wms("https://service.dofbasen.dk/geoserver/dof/wms", {
          layers: "dof:orto_foraar_wms",
          format: "image/png",
          attribution:
            '&copy; <a target="_blank" href="https://download.kortforsyningen.dk/content/vilk%C3%A5r-og-betingelser">Styrelsen for Dataforsyning og Effektivisering',
        })
        .addTo(toRaw(this.mymap));
    },
    setNewMarker(lat, lng) {
      this.marker = leaflet.marker([lat, lng]).addTo(this.mymap);
    },
    openNearestSitesModal() {
      this.nearestSites = [];
      this.getNearestSites();
      this.nearestSitesModal = new Modal(
        document.getElementById("nearestSitesModal"),
        {}
      );
      this.nearestSitesModal.show();
    },
    selectNearestSite(nearestSite) {
      this.collectionForm.site = nearestSite;
      this.nearestSitesModal.hide();
    },
    getNearestSites() {
      // Get the 15 nearest sites and insert as array into this.nearestSites
      let count = 0;
      let currentMaximumDistance = 0;
      let currentSecondMaximumDistance = 0;
      const currentPositionLat = this.collectionForm.latitude;
      const currentPositionLng = this.collectionForm.longitude;
      // For hver site
      this.sites.forEach((site) => {
        count++;
        // Få fat i distance i meter fra turens position
        let distanceInMeters = this.getDistance(
          site.la,
          site.lo,
          currentPositionLat,
          currentPositionLng
        );
        site.distanceInMeters = distanceInMeters;
        // Hvis der allerede er 10 lokaliteter tilføjer:
        if (count >= 16) {
          if (distanceInMeters < currentMaximumDistance) {
            let furthestAwaySite = this.nearestSites.reduce(function (
              prev,
              curr
            ) {
              return prev.distanceInMeters > curr.distanceInMeters
                ? prev
                : curr;
            });
            this.nearestSites = this.nearestSites.filter((newArraySite) => {
              return newArraySite.na !== furthestAwaySite.na;
            });
            let secondFurthestAwaySite = this.nearestSites.reduce(function (
              prev,
              curr
            ) {
              return prev.distanceInMeters > curr.distanceInMeters
                ? prev
                : curr;
            });
            this.nearestSites.push(site);

            if (distanceInMeters > currentSecondMaximumDistance) {
              currentMaximumDistance = distanceInMeters;
            }
            currentMaximumDistance = secondFurthestAwaySite.distanceInMeters;
          }
          // Hvis ikke der allerede er 10 lokaliteter tilføjet:
        } else {
          this.nearestSites.push(site);
          if (distanceInMeters > currentMaximumDistance) {
            currentSecondMaximumDistance = currentMaximumDistance;
            currentMaximumDistance = distanceInMeters;
          }
        }
      });
    },
    toRadian(degree) {
      return (degree * Math.PI) / 180;
    },
    getDistance(originLat, OriginLng, destinationLat, destinationLng) {
      // return distance in meters
      let radianLngOrigin = this.toRadian(OriginLng);
      let radianLatOrigin = this.toRadian(originLat);
      let radianLngDestination = this.toRadian(destinationLng);
      let radianLatDestination = this.toRadian(destinationLat);

      let deltaLat = radianLatOrigin - radianLatDestination;
      let deltaLng = radianLngOrigin - radianLngDestination;

      var a =
        Math.pow(Math.sin(deltaLat / 2), 2) +
        Math.cos(radianLatOrigin) *
          Math.cos(radianLatDestination) *
          Math.pow(Math.sin(deltaLng / 2), 2);
      var c = 2 * Math.asin(Math.sqrt(a));
      var EARTH_RADIUS = 6371;
      return c * EARTH_RADIUS * 1000;
    },
    async getSites() {
      this.sites = await siteRepository.getSites();
    },
  },

  mounted() {
    this.createMap();
    this.setNewMarker(
      this.collectionForm.latitude,
      this.collectionForm.longitude
    );
    this.getSites();
  },
};
</script>

<style scoped>
#tinymapid {
  height: 180px;
}

.modal-title {
  width: 100%;
}
</style>
