import idb from "../idb.js";

export default {
  async insertRecentBehaviour(recentBehaviour) {
    //First get all current recent behaviours, and delete any that shares same values as the new one
    let existingRecentBehaviours = await this.getRecentBehaviours();
    existingRecentBehaviours.forEach((existing) => {
      // Check if their values are the same:
      if (
        existing.primary == recentBehaviour.primary &&
        existing.secondary == recentBehaviour.secondary &&
        existing.direction == recentBehaviour.direction
      ) {
        this.deleteRecentBehavioursById(existing.id);
      }
    });

    let db = await idb.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["recentBehaviours"], "readwrite");
      let store = trans.objectStore("recentBehaviours");

      store.put(recentBehaviour);
    });
  },

  async deleteRecentBehavioursById(id) {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      db.transaction("recentBehaviours", "readwrite")
        .objectStore("recentBehaviours")
        .delete(+id);
    });
  },

  async getRecentBehaviours() {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["recentBehaviours"], "readonly");
      trans.oncomplete = () => {
        resolve(recentBehaviours.reverse().slice(0, 30));
      };

      let store = trans.objectStore("recentBehaviours");
      let recentBehaviours = [];

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          recentBehaviours.push(cursor.value);
          cursor.continue();
        }
      };
    });
  },
};
