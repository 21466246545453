<template>
  <div>
    <div id="edit-map"></div>
  </div>
</template>

<script>
import leaflet from "leaflet";
import proj4 from "proj4";
import pointInPolygon from "point-in-polygon";
import axios from "axios";
import { toRaw } from "vue";
leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
export default {
  name: "EditCollectionMap",
  props: ["sites", "collectionForm", "isShowingSiteMapModal"],
  data() {
    return {
      siteMap: null,
      interval: null,
      siteMarkers: null,
      mapSizeInvalidated: false,
      siteMarkerGroup: leaflet.layerGroup(),
      lastZoom: 14,
      currentSiteTitle: null,
      siteBorderLayer: null,
    };
  },
  watch: {
    isShowingSiteMapModal(newValue, oldValue) {
      if (newValue === true) {
        this.invalidateSize();
      }
    },
  },
  methods: {
    showPosition() {
      var crosshairIcon = leaflet.icon({
        iconUrl: require("../../assets/crosshairs-gps.png"),
        iconSize: [40, 40], // size of the icon
        iconAnchor: [20, 20], // point of the icon which will correspond to marker's location
      });
      let crosshair = new leaflet.marker(
        [this.collectionForm.latitude, this.collectionForm.longitude],
        { icon: crosshairIcon, clickable: false }
      );
      crosshair.addTo(toRaw(this.siteMap));
    },
    createMap() {
      let latitude = this.collectionForm.latitude;
      let longitude = this.collectionForm.longitude;
      let initialZoomLevel = 14;

      this.siteMap = leaflet
        .map("edit-map")
        .setView([latitude, longitude], initialZoomLevel);

      leaflet.tileLayer
        .wms("https://service.dofbasen.dk/geoserver/dof/wms", {
          layers: "dof:orto_foraar_wms",
          format: "image/png",
          transparent: true,
          attribution:
            '&copy; <a target="_blank" href="https://download.kortforsyningen.dk/content/vilk%C3%A5r-og-betingelser">Styrelsen for Dataforsyning og Effektivisering',
        })
        .addTo(toRaw(this.siteMap));

      leaflet.control.scale().addTo(toRaw(this.siteMap));

      this.siteMap.on("moveend", (e) => {
        this.mapMoveend();
      });

      this.showPosition();
    },
    mapMoveend() {
      if (!this.mapSizeInvalidated) {
        this.siteMap.invalidateSize();
        this.mapSizeInvalidated = true;
      }
      this.updateSitesOnMap();
    },
    async displaySiteBorder(siteNumber) {
      this.getSiteBorder(siteNumber)
        .then((response) => {
          let wkt = require("wkt");
          if (this.siteBorderLayer) {
            this.siteBorderLayer.remove();
          }
          this.siteBorderLayer = leaflet
            .geoJSON(wkt.parse(response.data))
            .addTo(toRaw(this.siteMap));
        })
        .catch((error) => {
          console.log("SiteBorder could not be fetched");
        });
    },
    getSiteBorder(siteNumber) {
      return axios.get("/public/gis/site/border/" + siteNumber);
    },
    updateSitesOnMap() {
      var mapBounds = this.siteMap.getBounds().pad(0.5);
      const zoom = this.siteMap.getZoom();
      const displayMarkerZoomLevel = 11;

      this.siteMarkerGroup.clearLayers();
      if (zoom >= displayMarkerZoomLevel) {
        this.sites.forEach((site) => {
          let latitude = site.la;
          let longitude = site.lo;
          if (mapBounds.contains([latitude, longitude])) {
            let marker = leaflet
              .marker([latitude, longitude])
              .bindPopup("<h5>" + site.na + "</h5>", { autoPan: false })
              .on("click", () => {
                this.displaySiteBorder(site.n);
                this.$emit("show-site-map-choice", site);
                this.currentSiteTitle = site.na;
              });
            this.siteMarkerGroup.addLayer(marker);
            if (site.na == this.currentSiteTitle) {
              marker.openPopup();
            }
          }
        });
        this.siteMarkerGroup.addTo(toRaw(this.siteMap));
      }
    },
    invalidateSize() {
      setTimeout(() => {
        this.siteMap.invalidateSize();
        this.updateSitesOnMap();
      }, 300);
    },
  },
  mounted() {
    this.createMap();
  },
};
</script>

<style scoped>
#edit-map {
  height: 500px;
  top: 0;
  width: 100%;
}
img.leaflet-marker-icon {
  filter: hue-rotate(244deg);
}

.green-text {
  text-color: #51af30;
}
</style>
