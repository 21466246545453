<template>
  <div>
    <div></div>
    <div class="mb-3 input-group">
      <span class="input-group-text required" id="specie-text">{{
        $englishPreferred ? "Specie" : "Art"
      }}</span>
      
      <Multiselect
        v-if="$englishPreferred"
        id="specie-picker"
        class="form-select"
        v-model="currentObservation.specie"
        label="englishName"
        valueProp="id"
        :placeholder="speciePlaceholder"
        :searchable="true"
        :filter-results="false"
        :min-chars="0"
        :delay="0"
        :options="
          async function (query) {
            return await fetchSpecies(query); // check JS block for implementation
          }
        "
        trackBy="danishName"
        ref="multiselect"
      />
      <Multiselect
        v-else
        id="specie-picker"
        class="form-select"
        v-model="currentObservation.specie"
        label="danishName"
        valueProp="id"
        :placeholder="speciePlaceholder"
        :searchable="true"
        :filter-results="false"
        :min-chars="0"
        :delay="0"
        :options="
          async function (query) {
            return await fetchSpecies(query); // check JS block for implementation
          }
        "
        trackBy="danishName"
        ref="multiselect"
      />

      <button
        @click="openSpeciesModal"
        class="btn btn-outline-secondary"
        type="button"
      >
        <i class="material-icons button-material-icon"> history </i>
      </button>
    </div>

    <div class="input-group mb-3">
      <span class="input-group-text required">{{
        $englishPreferred ? "Amount" : "Antal"
      }}</span>
      <input
        type="number"
        min="0"
        max="9900000"
        v-model="currentObservation.count"
        class="form-control"
      />
      <button
        class="btn btn-outline-secondary element-selector"
        v-on:click="addToAmount(1)"
        type="button"
      >
        +1
      </button>
      <button
        class="btn btn-outline-secondary element-selector"
        v-on:click="tenPlusToAmount()"
        type="button"
      >
        <span v-if="moreThanOneCount">+</span>10
      </button>
    </div>

    <div class="input-group mb-3">
      <span class="input-group-text required">{{
        $englishPreferred ? "Behaviour" : "Adfærd"
      }}</span>
      <Multiselect
        input.inputmode="none"
        v-if="$englishPreferred"
        id="primary-picker"
        class="form-select"
        v-model="currentObservation.chosenBehaviours.primary"
        :options="primaryBehaviours"
        label="englishDescription"
        valueProp="id"
        placeholder="Vælg adfærd"
        trackBy="danishDescription"
        @change="resetSecondaryAndDirection"
      />
      <Multiselect
        v-else
        id="primary-picker"
        class="form-select"
        v-model="currentObservation.chosenBehaviours.primary"
        :options="primaryBehaviours"
        label="danishDescription"
        valueProp="id"
        placeholder="Vælg adfærd"
        trackBy="danishDescription"
        @change="resetSecondaryAndDirection"
      />
      <button
        @click="fillRecentBehaviour"
        class="btn btn-outline-secondary"
        type="button"
      >
        <i class="material-icons button-material-icon"> history </i>
      </button>
    </div>

    <!-- Specie Modal -->
    <div
      class="modal fade"
      id="specieModal"
      tabindex="-1"
      aria-labelledby="specieModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="specieModalLabel">
              {{ $englishPreferred ? "Recent species" : "Seneste arter" }}
            </h5>
            <button
              type="button"
              class="btn-close speiceModalClose"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="closeSpecieModal()"
            ></button>
          </div>
          <div class="modal-body">
            <div v-if="recentSpecies.length > 0">
              <div
                class="mb-2"
                v-for="(recentSpecie, index) in recentSpecies"
                :key="index"
                @click="selectRecentSpecie(recentSpecie.specieId)"
              >
                {{
                  getSpecieName(recentSpecie.specieId) != null
                    ? getSpecieName(recentSpecie.specieId).danishName
                    : null
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="@vueform/multiselect/themes/default.css"></style>

<style scoped>
.required:before {
  content: "* ";
  color: red;
}

.element-selector {
  touch-action: manipulation;
}
</style>

<script>
import Multiselect from "@vueform/multiselect";
import specieRepository from "../../idb/repositories/specieRepository";
import primaryBehaviourRepository from "../../idb/repositories/primaryBehaviourRepository";
import recentSpeciesRepository from "../../idb/repositories/recentSpeciesRepository";
import recentBehavioursRepository from "../../idb/repositories/recentBehavioursRepository";
import secondaryBehaviourRepository from "../../idb/repositories/secondaryBehaviourRepository";
import directionRepository from "../../idb/repositories/directionRepository";
import { Modal } from "bootstrap";

export default {
  name: "MandatoryTab",
  props: ["currentObservation"],
  data() {
    return {
      currentSpecieSearchString: "",
      currentPrimarySearchString: "",
      testSearch: "",
      chosenSpeciesId: 0,
      primaryBehaviours: [],
      species: [],
      recentSpecies: [],
      recentBehaviours: [],
      behavioursModal: null,
      speciesModal: null,
      secondaryBehaviours: [],
      directions: [],
    };
  },
  watch: {
    $route(newVal, oldVal) {
      let hideModal = newVal.query?.isModalOpen || false;
      if (hideModal === false && this.speciesModal != null) {
        this.speciesModal.hide();
      } else if (this.speciesModal != null) {
        this.speciesModal.show();
      }
    }
  },
  computed: {
    speciePlaceholder() {
      if (this.currentObservation.specie != null && this.currentObservation != "") {
        let specie = this.getSpecieName(this.currentObservation.specie)
        if (specie != null && specie.danishName != null) {
          return specie.danishName;
        }
      }
      return this.$englishPreferred ? "Choose specie" : "Vælg art";
      
    },
    moreThanOneCount() {
      return this.currentObservation.count > 1;
    },
  },
  methods: {
    async fetchSpecies(query) {
      // We want to show name% before %name for species
      // We also want to show common -> uncommon -> rare -> escaped -> hybridOrUndetermined -> others as overall structure
      let allSpeciesMatching;
      let queryIsEmpty = query == null || query == "" ? true : false;

      //If query is null or empty show all species in dropdown
      if (queryIsEmpty) {
        allSpeciesMatching = this.species;
      } else {
        /*
            Find all species of these categories: dk == "X", hybridOrUndertermined == true, escaped == true, rare == true, uncommon == true, 
            and those that are in none of those categories
            Finally concat them all into one, in reverse order of how they are listed above
            */
        allSpeciesMatching = this.species.filter(
          (specie) =>
            specie.danishName.toUpperCase().indexOf(query.toUpperCase()) > -1
        );
      }

      let otherSpecies = [];
      let hybridOrUnderterminedSpecies = [];
      let escapedSpecies = [];
      let rareSpecies = [];
      let uncommonSpecies = [];
      let commonSpecies = [];

      allSpeciesMatching.forEach((specie) => {
        if (specie.dk.toUpperCase() === "X") {
          otherSpecies.push(specie);
        } else if (specie.hybridOrUndetermined) {
          hybridOrUnderterminedSpecies.push(specie);
        } else if (specie.escaped) {
          escapedSpecies.push(specie);
        } else if (specie.rare) {
          rareSpecies.push(specie);
        } else if (specie.uncommon) {
          uncommonSpecies.push(specie);
        } else {
          commonSpecies.push(specie);
        }
      });

      let beginsWithSpecies = [];
      let containsSpecies = [];
      if (queryIsEmpty === false) {
        let currentSpecieUppercase = query.toUpperCase();
        commonSpecies.forEach((specie) => {
          if (
            specie.danishName.toUpperCase().startsWith(currentSpecieUppercase)
          ) {
            beginsWithSpecies.push(specie);
          } else {
            containsSpecies.push(specie);
          }
        });
      } else {
        beginsWithSpecies = commonSpecies;
      }

      return beginsWithSpecies
        .concat(containsSpecies)
        .concat(uncommonSpecies)
        .concat(rareSpecies)
        .concat(escapedSpecies)
        .concat(hybridOrUnderterminedSpecies)
        .concat(otherSpecies);
    },
    async refreshRecent() {
      // Clear both recentBehaviours and recentSpecies
      this.recentSpecies = [];
      this.recentBehaviours = [];
      this.getRecentSpecies();
      this.getRecentBehaviours();
    },
    updateCurrentSpecieSearchString() {
      this.currentSpecieSearchString = document
        .getElementById("specie-picker")
        .getElementsByClassName("multiselect-search")[0]
        .getAttribute("modelvalue");
    },
    updateCurrentPrimarySearchString() {
      this.currentPrimarySearchString = document
        .getElementById("primary-picker")
        .getElementsByClassName("multiselect-search")[0]
        .getAttribute("modelvalue");
    },
    closeSpecieModal() {
      this.$router.replace({
        name : this.$route.name,
      })
    },
    openSpeciesModal() {
      this.refreshRecent();
      this.speciesModal = new Modal(document.getElementById("specieModal"), {});
      this.speciesModal.show();
      this.$router.push({
        name : this.$route.name,
        query : {
          isModalOpen : true
        }
      })
    },
    fillRecentBehaviour() {
      if (this.recentBehaviours != null && this.recentBehaviours.length > 0) {
        this.currentObservation.chosenBehaviours = this.recentBehaviours[0];
      }
      this.refreshRecent();
    },
    selectRecentSpecie(specieId) {
      this.currentObservation.specie = specieId;
      this.speciesModal.hide();
    },
    getSpecieName(specieId) {
      let result = this.species.filter((specie) => {
        return specie.id === specieId;
      });
      return result[0] != null ? result[0] : null;
    },
    getBehaviourName(behaviours) {
      let primary = behaviours.primary;
      let secondary = behaviours.secondary;
      let direction = behaviours.direction;
      // Gennemgå 'chosenBehaviours' for at finde det rette behaviour objekt
      // 1. se om denne observation har en retning som ikke er null eller tom string
      if (direction != null && direction.length !== 0) {
        // 1.1 Hvis den har, så tjek først om den har en secondary
        if (secondary != null) {
          // 1.1.1 Hvis den har en secondary, så find direction fra directions med tilsvarende 'secondaryId' og returner
          let result = this.directions.filter((direction) => {
            return direction.secondaryId === secondary;
          });
          return result[0] != null ? result[0] : null;
        } else {
          // 1.2 Hvis den ikke har secondary så find direction fra directions med tilsvarende 'primaryId' og returner
          let result = this.directions.filter((direction) => {
            return direction.primaryId === primary;
          });
          return result[0] != null ? result[0] : null;
        }
        // 2. se om denne observation har secondary
      } else if (secondary != null) {
        // 2.1 Hvis den har secondary så find tilsvarende secondaryBehaviour 'id' fra secondaryBehaviours og returner
        let result = this.secondaryBehaviours.filter((secondaryBehaviour) => {
          return secondaryBehaviour.id === secondary;
        });
        return result[0] != null ? result[0] : null;

        // 3. se om denne observation har primary
      } else if (primary != null) {
        // 3.1 Hvis den har primary så find tilsvarende primaryBehaviour 'id' fra primaryBehaviours og returner
        let result = this.primaryBehaviours.filter((primaryBehaviour) => {
          return primaryBehaviour.id === primary;
        });
        return result[0] != null ? result[0] : null;
      } else {
        // 4. ellers returner null
        return null;
      }
    },
    addToAmount(value) {
      if (this.currentObservation.count < 1) {
        this.currentObservation.count = 1;
      } else {
        this.currentObservation.count = this.currentObservation.count + value;
      }
    },
    tenPlusToAmount() {
      if (this.currentObservation.count > 1) {
        this.currentObservation.count = this.currentObservation.count + 10;
      } else {
        this.currentObservation.count = 10;
      }
    },
    resetSecondaryAndDirection() {
      this.currentObservation.chosenBehaviours.secondary = null;
      this.currentObservation.chosenBehaviours.direction = null;
    },
    async getSpecies() {
      this.species = await specieRepository.getSpecies();
      this.$refs.multiselect.refreshOptions()
    },
    async getPrimaryBehaviours() {
      this.primaryBehaviours =
        await primaryBehaviourRepository.getPrimaryBehaviours();
    },
    async getRecentSpecies() {
      this.recentSpecies = await recentSpeciesRepository.getRecentSpecies();
      this.recentSpecies = this.recentSpecies.slice(0, 20);
    },
    async getRecentBehaviours() {
      this.recentBehaviours =
        await recentBehavioursRepository.getRecentBehaviours();
      if (this.recentBehaviours != null && this.recentBehaviours.length > 0) {
        this.recentBehaviours = this.recentBehaviours.slice(0, 1);
      }
    },
    async getSecondaryBehaviours() {
      this.secondaryBehaviours =
        await secondaryBehaviourRepository.getSecondaryBehaviours();
    },
    async getDirections() {
      this.directions = await directionRepository.getDirections();
    },
  },
  beforeMount() {
    this.getSpecies();
    this.getPrimaryBehaviours();
    this.getSecondaryBehaviours();
    this.getDirections();
    this.getRecentSpecies();
    this.getRecentBehaviours();
  },
  components: {
    Multiselect,
  },
};
</script>
