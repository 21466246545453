<template>
  <div>
    <div class="container">
      <div>
        <h4 class="mb-4">{{ $englishPreferred ? "Fill date and start time" : "Angiv dato og starttidspunkt" }}</h4>
        <div class="row mb-3">
          <div class="col text-center">
            <div class="input-group input-group-lg">
              <label
                for="date"
                class="input-group-text"
                id="inputGroup-sizing-lg"
                >{{ $englishPreferred ? "Date" : "Dato" }}</label
              >
              <input
                type="date"
                id="date"
                :min="'1950-01-01'"
                :max="currentDate"
                v-model="collectionForm.date"
                class="form-control"
              />
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col text-center">
            <div class="input-group input-group-lg">
              <label
                for="time"
                class="input-group-text"
                id="inputGroup-sizing-lg"
                >{{ $englishPreferred ? "Start time" : "Starttid" }}</label
              >
              <input
                id="time"
                @change="setEndTime"
                type="time"
                v-model="collectionForm.startTime"
                class="form-control"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="allRequiredFieldsHasContent" class="bottom-nav">
      <button
        type="button"
        class="btn btn-default bottom-action"
        v-on:click="$emit('switch-to-observations')"
      >
        <i class="material-icons button-material-icon"> done </i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["collectionForm"],
  data() {
    return {};
  },
  computed: {
    allRequiredFieldsHasContent() {
      var allHasContent = false;
      if (
        this.collectionForm.startTime != null &&
        this.collectionForm.date != null
      ) {
        allHasContent = true;
      }

      return allHasContent;
    },
    currentDate() {
      let yourDate = new Date();
      yourDate.toISOString().split("T")[0];
      let offset = yourDate.getTimezoneOffset();
      yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
      return yourDate.toISOString().split("T")[0];
    },
  },
  methods: {
    setEndTime() {
      this.collectionForm.endTime = new Date(
        new Date("1970/01/01 " + this.collectionForm.startTime).getTime() +
          5 * 60000
      ).toLocaleTimeString("en-UK", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
    },
  },
};
</script>
