<template>
  <confirm-modal
    v-if="showModal"
    :title="modalTitle"
    :message="modalMessage"
    @confirmed="onConfirmed"
    @declined="onDeclined"
  />
  <div
    v-if="!observation.deleted"
    @click.self="promptEdit"
    v-bind:class="{ selected: observation.isBeingEdited }"
    class="list-group-item nav__link mb-2 observation-row"
  >
    <div class="row">
      <div class="col-2 text-start">
        <b class="small" @click="promptEdit">
          {{ observation.count }}
        </b>
      </div>

      <div @click="promptEdit" class="col-8 text-start">
        <b class="small" v-if="getSpeciesObject != null">{{
          $englishPreferred
            ? getSpeciesObject.englishName
            : getSpeciesObject.danishName
        }}</b>
        <br />
        <span v-if="getBehaviourObject != null" class="small">{{
          $englishPreferred
            ? getBehaviourObject.englishDescription
            : getBehaviourObject.danishDescription
        }}</span
        ><br />
        <span class="small">
          <span v-if="getSex != null">
            <i class="material-icons button-material-icon">
              {{ getSex }}
            </i>
            &emsp;
          </span>
          <span v-if="getAge != null">
            {{ $englishPreferred ? getAge.englishCode : getAge.danishCode }}
            &emsp;
          </span>
          <span v-if="getPlumage != null">
            {{
              $englishPreferred ? getPlumage.englishCode : getPlumage.danishCode
            }}
          </span>
        </span>
      </div>
      <div @click.self="promptEdit" class="col-2 text-end">
        <button
          v-if="!observation.isBeingEdited"
          type="button"
          @click="incrementSpecieCount"
          class="btn btn-primary element-selector"
        >
          +1
        </button>
        <button
          v-if="observation.isBeingEdited"
          type="button"
          @click="showConfirmModal"
          v-on:click.prevent
          class="btn btn-danger"
        >
          <i class="material-icons md-18 button-material-icon"> delete </i>
        </button>
        <span
          v-if="
            (!observation.apiId && isNewCount == false) ||
            (observation.unsynched && isNewCount == false)
          "
        >
          <i class="material-icons button-material-icon">upload</i>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import specieRepository from "../../idb/repositories/specieRepository";
import primaryBehaviourRepository from "../../idb/repositories/primaryBehaviourRepository";
import secondaryBehaviourRepository from "../../idb/repositories/secondaryBehaviourRepository";
import ageRepository from "../../idb/repositories/ageRepository";
import plumageRepository from "../../idb/repositories/plumageRepository";
import directionRepository from "../../idb/repositories/directionRepository";
import observationRepository from "../../idb/repositories/observationRepository";
import axios from "axios";
import ConfirmModal from "../ConfirmModal.vue";

export default {
  name: "observation-list-item",
  props: {
    observation: Object,
  },
  components: {
    ConfirmModal,
  },
  emits: ["removeFromArray", "editSelectedObservation"],
  data() {
    return {
      primaryBehaviours: [],
      species: [],
      ages: [],
      plumages: [],
      secondaryBehaviours: [],
      directions: [],
      isNewCount: false,
      showModal: false,
      modalTitle: "",
      modalMessage: "",
    };
  },
  methods: {
    showConfirmModal() {
      this.modalTitle = "Slet observation";
      this.modalMessage = "Bekræft at du vil slette denne observation";
      this.showModal = true;
    },
    onConfirmed() {
      // Handle the OK button click here
      // This function is called when the user clicks "OK" in the modal
      this.showModal = false;

      this.deleteObservation();
    },
    onDeclined() {
      this.showModal = false;
    },
    promptEdit() {
      this.$emit("editSelectedObservation", this.$.vnode.key);
      this.observation.isBeingEdited = true;
    },
    deleteObservation() {
      // Check if observation has apiId
      if (this.observation.apiId) {
        // First mark observation as deleted and then update in local database
        this.observation.deleted = true;
        let unwrappedObservation = {
          ...JSON.parse(JSON.stringify(this.observation)),
        };
        observationRepository
          .insertObservation(unwrappedObservation)
          .then(() => {
            // Delete observation in API, and when done, delete locally as well
            axios
              .delete("/spontan/observations/" + this.observation.apiId)
              .then((response) => {
                observationRepository.deleteObservationById(
                  this.observation.id
                );
              })
              .catch((error) => {
                console.log("Server did not respond, don't delete locally yet");
              });
          });
      } else {
        // Remove observation from local database if it has a collectionId
        if (this.observation.collectionId != null) {
          observationRepository.deleteObservationById(this.observation.id);
        }
      }
      // Remove observation from this array of observations using emit
      this.$emit("removeFromArray", this.$.vnode.key);
    },
    incrementSpecieCount() {
      // Increment the count of the current observation
      this.observation.count++;

      // If observation has a collectionId save the increment directly
      if (this.observation.collectionId != null) {
        // Copy the observation as a json object
        let unwrappedObservation = {
          ...JSON.parse(JSON.stringify(this.observation)),
        };
        if (unwrappedObservation.apiId) {
          unwrappedObservation.unsynched = true;
          // Update in API
          axios
            .put(
              "/spontan/observations/" + unwrappedObservation.apiId,
              unwrappedObservation
            )
            .then((response) => {
              console.log(
                "Successfully updated in DOFbasen, now updating in locally"
              );
              // Success, now update locally with unsynched marked as true
              unwrappedObservation.unsynched = false;
              observationRepository.insertObservation(unwrappedObservation);
            })
            .catch((error) => {
              console.log("Server did not respond, updating locally");
              observationRepository.insertObservation(unwrappedObservation);
            });
        } else {
          // Insert into indexedDB
          observationRepository.insertObservation(unwrappedObservation);
        }
      }
    },
    async getSpecies() {
      this.species = await specieRepository.getSpecies();
    },
    async getPrimaryBehaviours() {
      this.primaryBehaviours =
        await primaryBehaviourRepository.getPrimaryBehaviours();
    },
    async getSecondaryBehaviours() {
      this.secondaryBehaviours =
        await secondaryBehaviourRepository.getSecondaryBehaviours();
    },
    async getAges() {
      this.ages = await ageRepository.getAges();
    },
    async getPlumages() {
      this.plumages = await plumageRepository.getPlumages();
    },
    async getDirections() {
      this.directions = await directionRepository.getDirections();
    },
  },
  beforeMount() {
    this.getPrimaryBehaviours();
    this.getSpecies();
    this.getAges();
    this.getPlumages();
    this.getSecondaryBehaviours();
    this.getDirections();
    if (
      this.$route.path == "/taelling/ny" ||
      this.$route.path == "/taelling/indtast"
    ) {
      this.isNewCount = true;
    }
  },
  computed: {
    getPlumage() {
      if (this.observation.plumage != null && this.observation.plumage !== 1) {
        let result = this.plumages.filter((plumage) => {
          return plumage.id === this.observation.plumage;
        });
        return result[0] != null ? result[0] : null;
      } else {
        return null;
      }
    },
    getAge() {
      if (this.observation.age != null && this.observation.age !== 1) {
        let result = this.ages.filter((age) => {
          return age.id === this.observation.age;
        });
        return result[0] != null ? result[0] : null;
      }
    },
    getSpeciesObject() {
      if (this.observation.specie != null) {
        let result = this.species.filter((specie) => {
          return specie.id === this.observation.specie;
        });
        return result[0] != null ? result[0] : null;
      }
    },
    getSex() {
      if (
        this.observation.sex != null &&
        this.observation.sex.length !== 0 &&
        this.observation.sex !== "-"
      ) {
        if (this.observation.sex === "M") {
          return "male";
        } else if (this.observation.sex === "F") {
          return "female";
        }
      } else {
        return null;
      }
    },
    getBehaviourObject() {
      let primary = this.observation.chosenBehaviours.primary;
      let secondary = this.observation.chosenBehaviours.secondary;
      let directionTitle = this.observation.chosenBehaviours.direction;
      // Gennemgå 'chosenBehaviours' for at finde det rette behaviour objekt
      // 1. se om denne observation har en retning som ikke er null eller tom string
      if (directionTitle != null && directionTitle.length !== 0) {
        // 1.1 Hvis den har, så tjek først om den har en secondary
        if (secondary != null) {
          // 1.1.1 Hvis den har en secondary, så find direction fra directions med tilsvarende 'secondaryId' og returner
          let result = this.directions.filter((direction) => {
            return (
              direction.secondaryId === secondary &&
              direction.title === directionTitle
            );
          });
          return result[0] != null ? result[0] : null;
        } else {
          // 1.2 Hvis den ikke har secondary så find direction fra directions med tilsvarende 'primaryId' og returner
          let result = this.directions.filter((direction) => {
            return (
              direction.primaryId === primary &&
              direction.title === directionTitle
            );
          });
          return result[0] != null ? result[0] : null;
        }
        // 2. se om denne observation har secondary
      } else if (secondary != null) {
        // 2.1 Hvis den har secondary så find tilsvarende secondaryBehaviour 'id' fra secondaryBehaviours og returner
        let result = this.secondaryBehaviours.filter((secondaryBehaviour) => {
          return secondaryBehaviour.id === secondary;
        });
        return result[0] != null ? result[0] : null;

        // 3. se om denne observation har primary
      } else if (primary != null) {
        // 3.1 Hvis den har primary så find tilsvarende primaryBehaviour 'id' fra primaryBehaviours og returner
        let result = this.primaryBehaviours.filter((primaryBehaviour) => {
          return primaryBehaviour.id === primary;
        });
        return result[0] != null ? result[0] : null;
      } else {
        // 4. ellers returner null
        return null;
      }
    },
  },
};
</script>

<style scoped>
.element-selector {
  touch-action: manipulation;
}

.observation-row {
  cursor: pointer;
  background: linear-gradient(to right, #1f7aaf, #51af30);
  background-size: 100% 5px;
  background-position: bottom 0 left 0, bottom 5px left 0;
  background-repeat: no-repeat;
}

.selected {
  border-bottom: 3px solid #51af30;
}

div {
  white-space: normal;
}
</style>
