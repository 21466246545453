import idb from "../idb.js";

export default {
  async deleteObservationById(id) {
    let db = await idb.getDb();

    return new Promise(() => {
      db.transaction("observations", "readwrite")
        .objectStore("observations")
        .delete(+id);
    });
  },

  async insertObservation(observation) {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["observations"], "readwrite");
      let store = trans.objectStore("observations");

      let request = store.put(observation);
      request.onsuccess = function (e) {
        resolve(e.target.result);
      };
    });
  },
  async getObservationsByCollectionId(collectionId) {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["observations"], "readonly");
      trans.oncomplete = () => {
        resolve(observations);
      };

      let store = trans.objectStore("observations");
      let observations = [];

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          if (cursor.value.collectionId == collectionId) {
            observations.push(cursor.value);
          }
          cursor.continue();
        }
      };
    });
  },
  async getObservations() {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["observations"], "readonly");
      trans.oncomplete = () => {
        resolve(observations);
      };

      let store = trans.objectStore("observations");
      let observations = [];

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          observations.push(cursor.value);
          cursor.continue();
        }
      };
    });
  },
};
