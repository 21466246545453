<template>
  <div>
    <loading :active="isLoading" :is-full-page="true"></loading>
    <div class="center">
      <h2>{{ $englishPreferred ? "Add position" : "Angiv placering" }}</h2>
    </div>

    <div>
      <div class="map-marker-centered"></div>
      <div id="map"></div>
    </div>

    <div
      class="card map-card mx-auto"
      v-show="showCardInfo"
      @click="showCardInfo = !showCardInfo"
      v-if="showInstructions"
    >
      <div class="card-body text-center">
        <h5 class="card-title">
          {{
            $englishPreferred ? "Automatic positioning" : "Automatisk placering"
          }}
        </h5>
        <p class="card-text">
          {{
            $englishPreferred
              ? "The app is trying to find your position, but it requires your permission."
              : "Appen forsøger at angive din placering, men det kræver din tilladelse."
          }}<br />
          {{
            $englishPreferred
              ? "You can set it manually by zooming in on your position"
              : "Du kan sætte den manuelt ved at zoome ind på din placering"
          }}.
        </p>
      </div>
    </div>
    <div class="card map-card mx-auto" v-show="offlineMapIsActive">
      <div class="card-body text-center">
        <h5 class="card-title">
          {{ $englishPreferred ? "Offline map" : "Offlinekort" }}
        </h5>
        <p class="card-text">
          {{
            $englishPreferred
              ? 'Your position is estimated by satelite. You can check and edit the position in "My counts" next time you are online.'
              : 'Din placering estimeres via satelit. Tjek evt. tællingens placering under "Mine tællinger" næste gang du er online.'
          }}
        </p>
      </div>
    </div>

    <div class="bottom-nav">
      <button
        @click="getLocation()"
        type="button"
        class="bottom-action btn btn-primary map-button"
      >
        <i class="material-icons button-material-icon"> gps_fixed </i>
        {{ $englishPreferred ? "My position" : "Min placering" }}
      </button>
      <button
        @click="offlineMapToggle()"
        :class="offlineMapIsActive ? 'btn-primary' : 'btn-secondary'"
        class="bottom-action btn map-button"
        type="button"
        :id="offlineMapIsActive ? 'color-switch-btn' : ''"
      >
        <i class="material-icons button-material-icon">map</i>
        {{ offlineMapIsActive ? "Online kort" : "Offline kort" }}
      </button>
      <button
        type="button"
        class="bottom-action btn btn-default map-button"
        v-if="positionFound"
        @click="confirmLocation()"
      >
        <i class="material-icons button-material-icon"> done </i>
        {{
          wasLocationAlreadySet
            ? ""
            : $englishPreferred
            ? "Start count"
            : "Start tælling"
        }}
      </button>
    </div>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import leaflet from "leaflet";
import atlasSquareRepository from "../idb/repositories/atlasSquareRepository";
import siteRepository from "../idb/repositories/siteRepository";
import proj4 from "proj4";
import pointInPolygon from "point-in-polygon";
import axios from "axios";
import { toRaw } from "vue";
import localforage from "localforage";
// Import component
import Loading from "vue3-loading-overlay";
// Import stylesheet
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
// Turfjs
import * as turf from "@turf/turf";
const offlineMapImage = require("../assets/offline-denmark.png");

delete leaflet.Icon.Default.prototype._getIconUrl;
leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  props: ["collectionForm"],
  components: {
    Loading,
    localforage,
  },
  data() {
    return {
      offlineMapIsActive: false,
      attemptedInsertingOfflineMap: false,
      map: null,
      offlineMapImage,
      wasLocationAlreadySet: false,
      positionFound: false,
      showInstructions: true,
      gpsOptions: {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      },
      radiusCircle: null,
      showCardInfo: true,
      atlasSquares: [],
      atlasSquarePolygons: [],
      sites: [],
      ortoPreferred: false,
      isLoading: true,
      openStreetMapLayer: null,
      ortofotoLayer: null,
      offlineImageLayer: null,
      flying: false,
      accuracy: null,
    };
  },
  methods: {
    offlineMapToggle() {
      this.offlineMapIsActive = !this.offlineMapIsActive;
      if (this.offlineMapIsActive) {
        this.switchToOfflineMap();
      } else {
        this.switchToOnlineMap();
      }
    },
    switchToOnlineMap() {
      this.removeMapLayers();

      if (this.ortoPreferred == true) {
        this.ortofotoLayer.addTo(toRaw(this.map));
      } else {
        this.openStreetMapLayer.addTo(toRaw(this.map));
      }
    },
    switchToOfflineMap() {
      this.removeMapLayers();

      let latLngBounds = leaflet.latLngBounds([
        [54.430836, 7.706819],
        [57.963341, 15.56905],
      ]);
      this.offlineImageLayer = leaflet.imageOverlay(
        this.offlineMapImage,
        latLngBounds,
        {
          interactive: true,
        }
      );
      this.offlineImageLayer.addTo(toRaw(this.map));
      if (this.map.getZoom() > 11) {
        this.map.setZoom(11);
      }
      this.offlineMapIsActive = true;
    },
    removeMapLayers() {
      if (this.map.hasLayer(this.offlineImageLayer)) {
        this.map.removeLayer(this.offlineImageLayer);
      }
      if (this.map.hasLayer(this.openStreetMapLayer)) {
        this.map.removeLayer(this.openStreetMapLayer);
      }
      if (this.map.hasLayer(this.ortofotoLayer)) {
        this.map.removeLayer(this.ortofotoLayer);
      }
    },
    isLocationAlreadySet() {
      if (
        this.collectionForm.latitude != null &&
        this.collectionForm.longitude != null
      ) {
        return true;
      } else {
        return false;
      }
    },
    confirmLocation() {
      this.wasLocationAlreadySet = true;
      this.$emit("switch-to-date-and-time");
    },
    createMap() {
      this.wasLocationAlreadySet = this.isLocationAlreadySet();
      let latitude = this.isLocationAlreadySet()
        ? this.collectionForm.latitude
        : 55.993;
      let longitude = this.isLocationAlreadySet()
        ? this.collectionForm.longitude
        : 10.515;
      let zoomLevel = 7;
      if (this.isLocationAlreadySet()) {
        zoomLevel = 17;
        this.positionFound = true;
        this.showInstructions = false;
      }

      this.map = leaflet.map("map").setView([latitude, longitude], zoomLevel);

      this.openStreetMapLayer = leaflet.tileLayer(
        "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        {
          attribution:
            '<small><a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://www.openstreetmap.org/copyright">CC-BY-SA</a></small>',
          maxZoom: 18,
          id: "openstreetmap",
        }
      );

      this.ortofotoLayer = leaflet.tileLayer.wms(
        "https://service.dofbasen.dk/geoserver/dof/wms",
        {
          layers: "dof:orto_foraar_wms",
          format: "image/png",
          attribution:
            '&copy; <a target="_blank" href="https://download.kortforsyningen.dk/content/vilk%C3%A5r-og-betingelser">Styrelsen for Dataforsyning og Effektivisering',
        }
      );

      var baseLayers = {
        "Datafordeler Ortofoto": this.ortofotoLayer,
        OpenStreetMap: this.openStreetMapLayer,
      };

      // Add layer control to map
      this.baseLayers = leaflet.control
        .layers(baseLayers)
        .addTo(toRaw(this.map));

      leaflet.control.scale({ imperial: false }).addTo(toRaw(this.map));

      this.map.on("moveend", (e) => {
        this.mapMoveend();
      });

      // Offline map
      if (!navigator.onLine) {
        console.log("not online");
        this.switchToOfflineMap(leaflet);
      } else {
        if (this.ortoPreferred == true) {
          this.ortofotoLayer.addTo(toRaw(this.map));
        } else {
          this.openStreetMapLayer.addTo(toRaw(this.map));
        }
      }

      this.map.on("flystart", () => {
        this.flying = true;
        this.map.dragging.disable();
        this.map.doubleClickZoom.disable();
        this.map.scrollWheelZoom.disable();
      });
      this.map.on("flyend", () => {
        this.flying = false;
        this.map.dragging.enable();
        this.map.doubleClickZoom.enable();
        this.map.scrollWheelZoom.enable();
      });
    },
    async getSiteClosestToCoordinate() {
      let closestSite = null;
      let closestSiteDistanceInMeters = null;
      let closestSiteInsidePolygon = null;
      let closestSiteInsidePolygonDistanceInMeters = null;
      const currentPositionLat = this.collectionForm.latitude;
      const currentPositionLng = this.collectionForm.longitude;
      let promises = [];
      this.sites.forEach((site) => {
        let distanceInMeters = this.getDistance(
          site.la,
          site.lo,
          currentPositionLat,
          currentPositionLng
        );
        if (distanceInMeters != null && distanceInMeters < 8000) {
          promises.push(
            this.getSiteBorder(site.n)
              .then((response) => {
                let wkt = require("wkt");
                let parsedCoordinates = wkt.parse(response.data).coordinates[0];
                let correctedCoordinates = [];
                parsedCoordinates.forEach((x, i) => {
                  correctedCoordinates.push(x.reverse());
                });
                let turfPoint = turf.point([
                  currentPositionLat,
                  currentPositionLng,
                ]);
                let turfPolygon = turf.polygon([correctedCoordinates]);
                let turfWithin = turf.booleanWithin(turfPoint, turfPolygon);

                if (turfWithin === true) {
                  if (
                    closestSiteInsidePolygonDistanceInMeters == null ||
                    closestSiteInsidePolygonDistanceInMeters > distanceInMeters
                  ) {
                    closestSiteInsidePolygonDistanceInMeters = distanceInMeters;
                    closestSiteInsidePolygon = site;
                  }
                }
              })
              .catch(() => {
                console.log("Did not find borders for a location");
                return;
              })
          );
        }
        if (
          closestSiteDistanceInMeters == null ||
          closestSiteDistanceInMeters > distanceInMeters
        ) {
          closestSiteDistanceInMeters = distanceInMeters;
          closestSite = site;
        }
      });
      this.collectionForm.site = closestSite;
      if (
        this.collectionForm.atlasSquareNumber != null &&
        this.collectionForm.site != null
      ) {
        this.positionFound = true;
      }
      // Check if position is within site polygons
      Promise.all(promises).then(() => {
        if (closestSite != null) {
          if (closestSiteInsidePolygon != null) {
            if (closestSite.na != closestSiteInsidePolygon.na) {
              this.collectionForm.site = closestSiteInsidePolygon;
            } else {
              this.collectionForm.site = closestSite;
            }
          } else {
            this.collectionForm.site = closestSite;
          }
        }
      });
    },
    async getSiteBorder(siteNumber) {
      return axios.get("/public/gis/site/border/" + siteNumber);
    },
    getDistance(originLat, OriginLng, destinationLat, destinationLng) {
      // return distance in meters
      let radianLngOrigin = this.toRadian(OriginLng);
      let radianLatOrigin = this.toRadian(originLat);
      let radianLngDestination = this.toRadian(destinationLng);
      let radianLatDestination = this.toRadian(destinationLat);

      let deltaLat = radianLatOrigin - radianLatDestination;
      let deltaLng = radianLngOrigin - radianLngDestination;

      var a =
        Math.pow(Math.sin(deltaLat / 2), 2) +
        Math.cos(radianLatOrigin) *
          Math.cos(radianLatDestination) *
          Math.pow(Math.sin(deltaLng / 2), 2);
      var c = 2 * Math.asin(Math.sqrt(a));
      var EARTH_RADIUS = 6371;
      return c * EARTH_RADIUS * 1000;
    },
    toRadian(degree) {
      return (degree * Math.PI) / 180;
    },
    convertAtlasSquareToSimplePolygons() {
      this.atlasSquares.forEach((atlasSquare) => {
        var utm = "+proj=utm +zone=32";
        var wgs84 = "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs";
        var atlasSquarePolygon = {
          polygon: [
            proj4(utm, wgs84, atlasSquare.geometry.coordinates[0][0]).reverse(),
            proj4(utm, wgs84, atlasSquare.geometry.coordinates[0][1]).reverse(),
            proj4(utm, wgs84, atlasSquare.geometry.coordinates[0][2]).reverse(),
            proj4(utm, wgs84, atlasSquare.geometry.coordinates[0][3]).reverse(),
            proj4(utm, wgs84, atlasSquare.geometry.coordinates[0][4]).reverse(),
          ],
          kvadratnr: atlasSquare.properties.kvadratnr,
        };

        this.atlasSquarePolygons.push(atlasSquarePolygon);
      });
    },
    setNewRadiusCircle(lat, lng, radius) {
      this.clearRadiusCircle();
      this.radiusCircle = leaflet
        .circle([lat, lng], radius, { color: "#1f7aaf" })
        .addTo(toRaw(this.map));
    },
    clearRadiusCircle() {
      if (this.radiusCircle != undefined) {
        this.map.removeLayer(this.radiusCircle);
      }
    },
    async mapMoveend() {
      let lat = this.map.getCenter().lat;
      let lng = this.map.getCenter().lng;
      this.collectionForm.latitude = lat;
      this.collectionForm.longitude = lng;
      // Figure out which square pointer is in, (? maybe inform user if they are not in a square)
      this.showInstructions = false;
      this.positionFound = false;
      this.collectionForm.atlasSquareNumber = null;
      this.atlasSquarePolygons.forEach((atlasPolygonSquare) => {
        // Check if square polygon contains the current pointer coordinates, and update collection.atlasSquareNumber
        if (pointInPolygon([lat, lng], atlasPolygonSquare.polygon)) {
          this.collectionForm.atlasSquareNumber = atlasPolygonSquare.kvadratnr;
        }
      });
      await this.getSiteClosestToCoordinate();
      if (this.flying) {
        this.map.fire("flyend");
        this.setNewRadiusCircle(lat, lng, this.accuracy);
      }
    },
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.showPosition,
          this.gpsError,
          this.gpsOptions
        );
      } else {
        alert("Geolocation is not supported by this browser.");
        this.isLoading = false;
      }
    },
    gpsError() {
      console.log("Failed to find gps position");
      this.isLoading = false;
    },
    showPosition(position) {
      let zoomLevel = this.map.getZoom() < 17 ? 17 : this.map.getZoom();
      if (this.offlineMapIsActive) {
        zoomLevel = 11;
      }
      let latitude = position.coords.latitude;
      let longitude = position.coords.longitude;
      this.accuracy = position.coords.accuracy;
      this.map.flyTo([latitude, longitude], zoomLevel);
      this.map.fire("flystart");
    },
    async getAtlasSquares() {
      this.atlasSquares = await atlasSquareRepository.getAtlasSquares();
      this.convertAtlasSquareToSimplePolygons();
    },
    async getSites() {
      this.sites = await siteRepository.getSites();
    },
    async setOfflineImageMapFromIDB() {
      console.log("SetOfflineImageMapFromIDB");
      await localforage.getItem("offlineMap").then((offlineMap) => {
        if (offlineMap) {
          this.offlineMapImage = URL.createObjectURL(offlineMap);
        } else {
          if (this.attemptedInsertingOfflineMap == false) {
            //offlineMap not found - attempt insert and then use
            this.attemptedInsertingOfflineMap = true;
            this.prepareOfflineMap();
          }
        }
      });
    },
    async prepareOfflineMap() {
      fetch(this.offlineMapImage)
        .then((res) => res.blob())
        .then((blob) => {
          localforage.setItem("offlineMap", blob);
        });
    },
  },
  async mounted() {
    if ($cookies.isKey("ortoPreferred")) {
      this.ortoPreferred = true;
    }
    await this.setOfflineImageMapFromIDB();
    this.createMap();
    this.getAtlasSquares();
    await this.getSites().then(() => {
      this.isLoading = false;
      if (this.collectionForm.latitude == null) {
        this.getLocation();
      }
    });
  },
};
</script>

<style scoped>
.center {
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.map-marker-centered {
  background-image: url("~@/assets/crosshairs-gps.png");
  width: 48px;
  height: 48px;
  position: absolute;
  z-index: 2;
  left: calc(50% - 24px);
  top: calc(50% + 4px);
  transition: all 0.4s ease;
  opacity: 0.6;
}
</style>
