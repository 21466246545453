<template>
  <div class="map-div">
    <div id="observationMap"></div>
    <div class="mt-3" v-if="radiusCircle != null">
      <div class="text-center">
        Radius: {{ newCoordinate.radius }} meter
        <input
          @change="updateRadiusCircle"
          v-model="newCoordinate.radius"
          type="range"
          class="form-range"
          min="5"
          max="500"
          id="radiusRange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import leaflet from "leaflet";
import { toRaw } from "vue";
delete leaflet.Icon.Default.prototype._getIconUrl;
leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
export default {
  name: "LocationMap",
  props: [
    "observation",
    "collection",
    "isShowingObservationMapModal",
    "newCoordinate",
  ],
  emits: ["updateObservationCoordinate"],
  data() {
    return {
      observationMap: null,
      interval: null,
      mapSizeInvalidated: false,
      lastZoom: 14,
      observationMarker: null,
      radiusCircle: null,
    };
  },
  watch: {
    isShowingObservationMapModal(newValue, oldValue) {
      if (newValue === true) {
        this.invalidateSize();
        if (this.observationMarker != null) {
          this.observationMap.removeLayer(this.observationMarker);
        }
        if (
          this.observation.coordinate?.latitude != null &&
          this.observation.coordinate?.longitude != null
        ) {
          this.newCoordinate.longitude = this.observation.coordinate.longitude;
          this.newCoordinate.latitude = this.observation.coordinate.latitude;
          this.newCoordinate.radius = this.observation.coordinate.radius;
          this.setPosition(
            this.observation.coordinate.latitude,
            this.observation.coordinate.longitude
          );
        }
      }
    },
  },
  methods: {
    updateRadiusCircle() {
      let radiusInteger = parseInt(this.newCoordinate.radius);
      this.setNewRadiusCircle(
        this.newCoordinate.latitude,
        this.newCoordinate.longitude,
        radiusInteger
      );
      this.$emit(
        "updateObservationCoordinate",
        this.newCoordinate.latitude,
        this.newCoordinate.longitude,
        radiusInteger
      );
    },
    setNewRadiusCircle(lat, lng, radius) {
      this.clearRadiusCircle();
      this.radiusCircle = leaflet
        .circle([lat, lng], radius, { color: "#1f7aaf" })
        .addTo(toRaw(this.observationMap));
    },
    clearRadiusCircle() {
      if (this.radiusCircle != undefined) {
        this.observationMap.removeLayer(this.radiusCircle);
      }
    },
    setPosition(latitude, longitude) {
      if (this.observationMarker != null) {
        this.observationMap.removeLayer(this.observationMarker);
      }
      this.observationMarker = new leaflet.marker([latitude, longitude]);
      this.observationMarker.addTo(toRaw(this.observationMap));

      let radiusInteger = parseInt(this.newCoordinate.radius);
      this.setNewRadiusCircle(latitude, longitude, radiusInteger);
      this.$emit(
        "updateObservationCoordinate",
        latitude,
        longitude,
        radiusInteger
      );
    },
    createMap() {
      let latitude = this.collection.latitude;
      let longitude = this.collection.longitude;
      let initialZoomLevel = 14;

      this.observationMap = leaflet
        .map("observationMap")
        .setView([latitude, longitude], initialZoomLevel);

      leaflet.tileLayer
        .wms("https://service.dofbasen.dk/geoserver/dof/wms", {
          layers: "dof:orto_foraar_wms",
          format: "image/png",
          transparent: true,
          attribution:
            '&copy; <a target="_blank" href="https://download.kortforsyningen.dk/content/vilk%C3%A5r-og-betingelser">Styrelsen for Dataforsyning og Effektivisering',
        })
        .addTo(toRaw(this.observationMap));

      leaflet.control.scale().addTo(toRaw(this.observationMap));

      let crosshairIcon = leaflet.icon({
        iconUrl: require("../../assets/crosshairs-gps.png"),
        iconSize: [40, 40], // size of the icon
        iconAnchor: [20, 20], // point of the icon which will correspond to marker's location
      });
      let position = new leaflet.marker([latitude, longitude], {
        icon: crosshairIcon,
        clickable: false,
      });
      position.addTo(toRaw(this.observationMap));

      if (
        this.observation.coordinate?.latitude != null &&
        this.observation.coordinate?.longitude != null
      ) {
        this.newCoordinate.longitude = this.observation.coordinate.longitude;
        this.newCoordinate.latitude = this.observation.coordinate.latitude;
        this.newCoordinate.radius = this.observation.coordinate.radius;
        this.setPosition(
          this.observation.coordinate.latitude,
          this.observation.coordinate.longitude
        );
      }

      this.observationMap.on("click", (e) => {
        this.setPosition(e.latlng.lat, e.latlng.lng);
      });

      this.observationMap.on("moveend", (e) => {
        this.mapMoveend();
      });
    },
    mapMoveend() {
      if (!this.mapSizeInvalidated) {
        this.observationMap.invalidateSize();
        this.mapSizeInvalidated = true;
      }
    },
    invalidateSize() {
      setTimeout(() => {
        this.observationMap.invalidateSize();
      }, 300);
    },
  },
  mounted() {
    this.createMap();
  },
};
</script>

<style scoped>
#observationMap {
  height: 500px;
  top: 0;
  width: 100%;
}
img.leaflet-marker-icon {
  filter: hue-rotate(244deg);
}

.map-marker-centered {
  background-image: url("~@/assets/crosshairs-gps.png");
  width: 48px;
  height: 48px;
  position: absolute;
  z-index: 2;
  left: calc(50% - 24px);
  top: calc(50% + 4px);
  transition: all 0.4s ease;
  opacity: 0.6;
}
</style>
