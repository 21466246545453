<template>
  <div>
    <div class="container">
      <observation-form-area
        :currentObservation="currentObservation"
        :collectionForm="collectionForm"
      >
      </observation-form-area>
    </div>

    <div class="bottom-nav">
      <button
        v-if="collectionForm.site != null"
        type="button"
        class="btn bottom-action btn-default"
        v-on:click="observationsCompleted"
      >
        <i class="material-icons button-material-icon"> done </i>
        {{ $englishPreferred ? "Done" : "Afslut"}}
      </button>
    </div>
  </div>
</template>

<script>
import ObservationFormArea from "./observation/ObservationFormArea.vue";

export default {
  name: "AddCompletedObservationsToCount",
  props: ["currentObservation", "collectionForm"],
  components: {
    ObservationFormArea,
  },
  methods: {
    cancelCount() {
      this.stopTimer();
      this.$router.push("/");
    },
    observationsCompleted() {
      // Check if there's any observations, and if not, show pop-up window prompting user to confirm no observations
      if (this.collectionForm.observations.length < 1) {
        if (
          confirm(
            this.$englishPreferred ? "You have not added any observations - is it correct that there was no observations?" : "Du har ikke tilføjet nogen observationer - er det korrekt at der ingen observationer var?"
          )
        ) {
          this.$emit("submit-completed-count");
        }
      } else {
        this.$emit("submit-completed-count");
      }
    },
  },
};
</script>

<style scoped>
.md-48 {
  position: relative;
  /* Adjust these values accordingly */
  top: 9px;
  font-size: 38px !important;
}

.bottom-action {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  min-width: 50px;
  overflow: hidden;
  white-space: nowrap;
  font-family: sans-serif;
  font-size: 13px;
  color: #ffff;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  transition: background-color 0.1s ease-in-out;
}
</style>
