<template>
  <div>
    <div class="input-group mb-3" v-if="canHaveSecondaryBehaviour">
      <span class="input-group-text">{{ $englishPreferred ? "Secondary beh." : "Sekundær adf." }}</span>
      <Multiselect
        v-if="$englishPreferred"
        class="form-select"
        v-model="currentObservation.chosenBehaviours.secondary"
        :options="getSecondaryBehavioursForPrimary"
        label="englishDescription"
        valueProp="id"
        placeholder="Vælg"
        trackBy="danishDescription"
        ref="multiselect"
        @change="resetDirection"
      />
      <Multiselect
        v-else
        class="form-select"
        v-model="currentObservation.chosenBehaviours.secondary"
        :options="getSecondaryBehavioursForPrimary"
        label="danishDescription"
        valueProp="id"
        placeholder="Vælg"
        trackBy="danishDescription"
        ref="multiselect"
        @change="resetDirection"
      />
    </div>
    <div class="input-group mb-3" v-if="canHaveDirection">
      <span class="input-group-text">{{ $englishPreferred ? "Direction" : "Retning" }}</span>
      <Multiselect
      v-if="$englishPreferred"
        class="form-select"
        v-model="currentObservation.chosenBehaviours.direction"
        :options="directionChoices"
        label="englishFullTitle"
        valueProp="title"
        placeholder="Vælg retning"
        trackBy="danishFullTitle"
        ref="multiselect"
      />
      <Multiselect
        v-else
        class="form-select"
        v-model="currentObservation.chosenBehaviours.direction"
        :options="directionChoices"
        label="danishFullTitle"
        valueProp="title"
        placeholder="Vælg retning"
        trackBy="danishFullTitle"
        ref="multiselect"
      />
    </div>

    <div class="mb-3">
      <label for="location" class="form-label">
        {{
          currentObservation.coordinate?.latitude != null
            ? $englishPreferred ? "Position is set" : "Placering er sat"
            : $englishPreferred ? "Set position" : "Sæt placering"
        }}
      </label>
      <button
        type="button"
        v-on:click="openObservationMapModal()"
        class="btn-lg form-control btn-secondary mb-3"
      >
        <span class="material-icons button-material-icon"> location_on </span>
        <span>
          {{
            currentObservation.coordinate?.latitude != null
              ? this.$englishPreferred ? "Change position" : "Skift placering"
              : this.$englishPreferred ? "Choose position" : "Vælg placering"
          }}
        </span>
      </button>
    </div>

    <div class="input-group mb-3">
      <span class="input-group-text">{{ $englishPreferred ? "Notes" : "Noter" }}</span>
      <textarea
        v-model="currentObservation.notes"
        class="form-control"
        id="notes"
      ></textarea>
    </div>

    <div class="mb-3">
      <label for="sex" class="form-label">{{ $englishPreferred ? "Sex" : "Køn" }}</label>
      <div class="row">
        <div class="col-auto">
          <div class="form-check form-check-inline">
            <input
              v-model="currentObservation.sex"
              class="form-check-input"
              type="radio"
              name="sex"
              id="sexUnspecified"
              value="-"
              checked
            />
            <label class="form-check-label" for="sexUnspecified"
              >{{ $englishPreferred ? "Unspecified" : "Uspecificeret"}}</label
            >
          </div>
          <div class="form-check form-check-inline">
            <input
              v-model="currentObservation.sex"
              class="form-check-input"
              type="radio"
              name="sex"
              value="M"
              id="sexMale"
            />
            <label class="form-check-label" for="sexMale">{{ $englishPreferred ? "Male" : "Han" }}</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              v-model="currentObservation.sex"
              class="form-check-input"
              type="radio"
              name="sex"
              value="F"
              id="sexFemale"
            />
            <label class="form-check-label" for="sexFemale">{{ $englishPreferred ? "Female" : "Hun" }}</label>
          </div>
        </div>
      </div>
    </div>

    <div class="input-group mb-3">
      <span class="input-group-text">{{ $englishPreferred ? "Age" : "Alder" }}</span>
      <select
        class="form-select"
        name="age"
        id="age"
        v-model="currentObservation.age"
      >
        <option v-for="age in ages" :key="age.id" v-bind:value="age.id">
          <div class="row">
            <span v-if="age.id != 1">{{ $englishPreferred ? age.englishCode :  age.danishCode }}</span>
            {{ $englishPreferred ?age.englishDescription :  age.danishDescription }}
          </div>
        </option>
      </select>
    </div>

    <div class="input-group mb-3">
      <span class="input-group-text">{{ $englishPreferred ? "Plumage" : "Dragt" }}</span>
      <select
        class="form-select"
        name="plumage"
        id="plumage"
        v-model="currentObservation.plumage"
      >
        <option
          v-for="plumage in plumages"
          :key="plumage.id"
          v-bind:value="plumage.id"
        >
          <span v-if="plumage.id != 1">{{ $englishPreferred ? plumage.englishCode : plumage.danishCode }}</span>
          {{ $englishPreferred ? plumage.englishDescription : plumage.danishDescription }}
        </option>
      </select>
    </div>
    <hr />

    <div class="mb-3 row">
      <div class="col-6 text-start">
        <div class="form-check">
          <input
            class="form-check-input"
            v-model="currentObservation.calling"
            type="checkbox"
            value="true"
            v-bind:id="'calling-checkbox'"
          />
          <label class="form-check-label" v-bind:for="'calling-checkbox'">
            {{ $englishPreferred ? "Calling" : "Kaldende" }}
          </label>
        </div>
      </div>
      <div class="col-6 text-start">
        <div class="form-check">
          <input
            class="form-check-input"
            v-model="currentObservation.heard"
            type="checkbox"
            value="true"
            v-bind:id="'heard-checkbox'"
          />
          <label class="form-check-label" v-bind:for="'heard-checkbox'">
            {{ $englishPreferred ? "Only heard" : "Kun hørt" }}
          </label>
        </div>
      </div>
      <div class="col-6 text-start">
        <div class="form-check">
          <input
            class="form-check-input"
            v-model="currentObservation.migratingInwards"
            type="checkbox"
            value="true"
            v-bind:id="'migrating-inwards-checkbox'"
          />
          <label
            class="form-check-label"
            v-bind:for="'migrating-inwards-checkbox'"
          >
          {{ $englishPreferred ? "Migrating inwards" : "Indtrækkende" }}
          </label>
        </div>
      </div>
      <div class="col-6 text-start">
        <div class="form-check">
          <input
            class="form-check-input"
            v-model="currentObservation.migratingOutwards"
            type="checkbox"
            value="true"
            v-bind:id="'migrating-outwards-checkbox'"
          />
          <label
            class="form-check-label"
            v-bind:for="'migrating-outwards-checkbox'"
          >
          {{ $englishPreferred ? "Migrating outwards" : "Udtrækkende" }}
          </label>
        </div>
      </div>
      <div class="col-6 text-start">
        <div class="form-check">
          <input
            class="form-check-input"
            v-model="currentObservation.oilDamaged"
            type="checkbox"
            value="true"
            v-bind:id="'oil-damaged-checkbox'"
          />
          <label class="form-check-label" v-bind:for="'oil-damaged-checkbox'">
            {{ $englishPreferred ? "Oildamaged" : "Olieskadet" }}
          </label>
        </div>
      </div>
    </div>
    <hr />

    <div class="mb-3">
      <Popper>
        <label for="secret" class="form-label">
          <i class="material-icons button-material-icon"> info </i>
          {{ $englishPreferred ? "Secret" : "Hemmelig" }}
        </label>
        <template #content>
          <div class="alert alert-secondary" role="alert">
            {{ secretDescription }}
          </div>
        </template>
      </Popper>
      <div class="form-check text-start">
        <input
          v-model="currentObservation.secret"
          class="form-check-input"
          type="checkbox"
          id="secretChecked"
          value="true"
        />
        <label class="form-check-label" for="secretChecked">
          {{ $englishPreferred ? "Mark checkbox to keep observation secret" : "Sæt hak i checkboxen for at gøre denne observation hemmelig" }}
        </label>
      </div>
    </div>

    <!--Observation Position Map Modal-->
    <div
      class="modal fade"
      id="observationMapModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="observationMapModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-title" id="observationMapModalLabel">
              <h5>{{ $englishPreferred ? "Set position for observation" : "Angiv placering for observation" }}</h5>
              <span v-if="positionIsSet">
                <br />
                <button
                  @click="confirmCoordinate"
                  class="btn form-control btn-default"
                >
                  OK
                </button>
              </span>
            </div>
            <button
              type="button"
              @click="closeObservationMapModal()"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <location-map
              :newCoordinate="newCoordinate"
              :isShowingObservationMapModal="isShowingObservationMapModal"
              :observation="currentObservation"
              :collection="collection"
              @update-observation-coordinate="updateObservationCoordinate"
            ></location-map>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="@vueform/multiselect/themes/default.css"></style>

<script>
import Popper from "vue3-popper";
import Multiselect from "@vueform/multiselect";
import LocationMap from "../observation/LocationMap.vue";
import secondaryBehaviourRepository from "../../idb/repositories/secondaryBehaviourRepository";
import directionRepository from "../../idb/repositories/directionRepository";
import plumageRepository from "../../idb/repositories/plumageRepository";
import ageRepository from "../../idb/repositories/ageRepository";
import EditCollectionMap from "../collection/EditCollectionMap.vue";
import { Modal } from "bootstrap";

export default {
  name: "OptionalTab",
  components: {
    Popper,
    Multiselect,
    LocationMap,
    EditCollectionMap,
  },
  methods: {
    resetDirection() {
      if (this.canHaveDirection) {
        this.currentObservation.chosenBehaviours.direction = null;
      }
    },
    confirmCoordinate() {
      this.currentObservation.coordinate = this.newCoordinate;
      this.closeObservationMapModal();
    },
    updateObservationCoordinate(latitude, longitude, radius) {
      this.newCoordinate.latitude = latitude;
      this.newCoordinate.longitude = longitude;
      this.newCoordinate.radius = radius;
    },
    closeObservationMapModal() {
      this.observationMapModal.hide();
      this.isShowingObservationMapModal = false;
    },
    openObservationMapModal() {
      this.newCoordinate = {
        latitude: null,
        longitude: null,
        radius: 50,
      };
      this.observationMapModal = new Modal(
        document.getElementById("observationMapModal")
      );
      this.observationMapModal.show();
      this.isShowingObservationMapModal = true;
    },
    async getAges() {
      this.ages = await ageRepository.getAges();
    },
    async getSecondaryBehaviours() {
      this.secondaryBehaviours =
        await secondaryBehaviourRepository.getSecondaryBehaviours();
    },
    async getDirections() {
      this.directions = await directionRepository.getDirections();
    },
    async getPlumages() {
      this.plumages = await plumageRepository.getPlumages();
    },
  },
  beforeMount() {
    this.getAges();
    this.getSecondaryBehaviours();
    this.getDirections();
    this.getPlumages();
  },
  computed: {
    positionIsSet() {
      if (
        this.newCoordinate.latitude != null &&
        this.newCoordinate.longitude != null
      ) {
        return true;
      } else {
        return false;
      }
    },
    getSecondaryBehavioursForPrimary() {
      if (this.currentObservation.chosenBehaviours.primary != null) {
        //Figure out if there's any secondary behaviour(s) for the chosen primary behaviour,
        //return true if there is
        var result = this.secondaryBehaviours.filter((secondaryBehaviour) => {
          return (
            secondaryBehaviour.primaryId ===
            this.currentObservation.chosenBehaviours.primary
          );
        });

        return result;
      }
    },
    canHaveSecondaryBehaviour() {
      if (
        this.getSecondaryBehavioursForPrimary != null &&
        this.getSecondaryBehavioursForPrimary.length > 0
      ) {
        return true;
      }
      return false;
    },
    getDirectionsForChosenBehaviour() {
      if (this.currentObservation.chosenBehaviours.secondary != null) {
        var result = this.directions.filter((direction) => {
          return (
            direction.secondaryId ===
            this.currentObservation.chosenBehaviours.secondary
          );
        });
        return result;
      }
      if (this.currentObservation.chosenBehaviours.primary != null) {
        var result = this.directions.filter((direction) => {
          return (
            direction.primaryId ===
            this.currentObservation.chosenBehaviours.primary
          );
        });
        return result;
      }
    },
    canHaveDirection() {
      if (
        this.getDirectionsForChosenBehaviour != null &&
        this.getDirectionsForChosenBehaviour.length > 0
      ) {
        return true;
      }
      return false;
    },
  },
  props: ["currentObservation", "collection"],
  data() {
    return {
      newCoordinate: {
        latitude: null,
        longitude: null,
        radius: 50,
      },
      observationMapModal: null,
      isShowingObservationMapModal: false,
      ages: [],
      plumages: [],
      secondaryBehaviours: [],
      directions: [],
      secretDescription:
        "Hvis du vælger, at observationen skal være hemmelig, vil den ikke blive vist for andre brugere af DOFbasen. DOF forbeholder sig dog ret til at anvende den i videnskabelige og i beskyttelsesmæssige sammenhænge, herunder videregivelse til relevante forskere, myndigheder og deres konsulenter",
      directionChoices: [
        {
          title: "N",
          danishFullTitle: "Nord",
          englishFullTitle: "North",
        },
        {
          title: "NV",
          danishFullTitle: "Nordvest",
          englishFullTitle: "Northwest",
        },
        {
          title: "NØ",
          danishFullTitle: "Nordøst",
          englishFullTitle: "Northeast",
        },
        {
          title: "S",
          danishFullTitle: "Syd",
          englishFullTitle: "South",
        },
        {
          title: "SV",
          danishFullTitle: "Sydvest",
          englishFullTitle: "Southwest",
        },
        {
          title: "SØ",
          danishFullTitle: "Sydøst",
          englishFullTitle: "Southeast",
        },
        {
          title: "V",
          danishFullTitle: "Vest",
          englishFullTitle: "West",
        },
        {
          title: "Ø",
          danishFullTitle: "Øst",
          englishFullTitle: "East",
        },
      ],
    };
  },
};
</script>
